import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollOnTop/ScrollOnTop';
import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/context/SidebarContext'; 
import * as serviceWorker from 'src/serviceWorker';
import AlertMessage from './components/Alert/Alert';
import WhatsappFloat from './components/WhatsappFloat/WhatsappFloat';
import Popup from './components/Popup/Popup';
import LgpdModal from './components/LgpdModal/LgpdModal';



ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ScrollToTop />
        {/*<LgpdModal/>*/}
        {/*<Popup/>*/}
        <App/>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
