import { json } from 'express';
import React, { useEffect } from 'react';
import { createContext, useState, FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { logar, TOKEN_POST, POST_EMAIL_RECOVERY } from 'src/api/api';
import { LOGIN } from 'src/api/User/user';
import http from 'src/http';
import { ILogin, IUser } from 'src/models/login';

export const UserContext = createContext<IUser>({
    login: false,
    emailUser: '',
    passwordUser: '',
    levelUser: '',
    idPerfil: 0,
    userLogin: () => ({}),
    recoveryPassword: ()=>({}),
    setError: ()=> '',
    error: '',
    usuario: null,
    message: '',
    tokenUser: '',
})


export const UserStorage = ({ children }: any) => {

    const [emailUser, setEmailUser] = useState<string>(undefined);
    const [passwordUser, setPasswordUser] = useState<string>("");
    const [levelUser, setLevelUser] = useState<string>("");
    const [idPerfil, setIdPerfil] = useState(0);
    const [data, setData] = React.useState(null);
    const [login, setLogin] = React.useState(null);
    const [tokenUser, setTokenUser] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('')
    const [modal, setModal] = React.useState('')
    const [message, setMessage] = React.useState(null)
    const navigate = useNavigate()

    const [usuario, setUsuario] = useState();
 
    const { search } = useLocation();
    const redirect = new URLSearchParams(search).get("redirect");


    async function alreadyLogin() {
        const token = window.localStorage.getItem('token');
        if (token) {
            const user = JSON.parse(window.localStorage.getItem('user'));
            setUsuario(user);
            logar(token);
        }
    }

    const recoveryPassword = async (emailUser: string)=>{
        try{
            setError(null)
            setLoading(true)
            const { url, options } = POST_EMAIL_RECOVERY(emailUser);
            const response = await fetch(url, options)
            const json = await response.json();
        }catch(err){
            console.log(err)
        }
    }


    const userLogin = async (emailUser: string, passwordUser: string) => {
      
        if(emailUser && passwordUser){
            try {
             
                setLoading(true)
                http.post('usuarios/login', {
                    password: passwordUser,
                    login: emailUser
                })
                .then(response=>{
                    if(response.status === 200){
                        setMessage('Login efetuado com sucesso');
                        const { jwt: {type, token}, user} = response.data;
                        window.localStorage.setItem('token', token);
                        window.localStorage.setItem('user', JSON.stringify(user));
                        setLogin(true);
                        setUsuario(user);
                        if(user?.id_perfil == 1  || user?.id_perfil === 3){
                            navigate(redirect || '/dashboard/admin/geral')
                        }else{
                            navigate(redirect || '/dashboard/cliente/meus-pedidos')
                        }
                    }
                }).catch(err=>{
                    console.log(err)
                    if(err.response.data.message === "Senha inválida!"){
                        setError('Senha inválida!');
                    }else if(err.response.status === 500){
                        setError('Error de servidor interno!');
                    }
                    else if(err.response.data.message === "Usuário não existe!"){
                        setError('Usuário não existe!');
                    }
                })
            } catch(err) {
                setLogin(false)
            } finally {
                setLoading(false)
            }
        }else{
            setError('Preencha todos os campos obrigatórios')
        }
    }


    useEffect(() => {
        alreadyLogin();
    }, []);



    return (
        <UserContext.Provider value={{
            message,
            tokenUser,
            setError:(error)=> error,
            error,
            login,
            emailUser,
            passwordUser,
            idPerfil,
            levelUser,
            usuario,
            recoveryPassword,
            userLogin
        }}>
            {children}
        </UserContext.Provider>
    )
}