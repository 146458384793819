


import React from "react";
import styles from './WebFormLp.module.scss'
import {LoadingButton} from '@mui/lab'


export default function WebFormLp() {
    return (
        <form 
            action='https://forms.zohopublic.com/nestrentalbr/form/FormulrioNovaPgina/formperma/WQ8DdM0j3CInYeedzviF3o9Au7Py2xqL3Q3lQjpE9H4/htmlRecords/submit'
            name='form'
            id='form'
            method='POST'
            acceptCharset='UTF-8' 
            encType='multipart/form-data'
            className={styles.formWebLp}
        >
            <input type="hidden" name="zf_referrer_name" value=""/>
            <input type="hidden" name="zf_redirect_url" value=""/>
            <input type="hidden" name="zc_gad" value=""/>

            <div className={styles.formWebLp__divBox}>
                <label>Primeiro Nome</label>
                <input type="text"  name="Name_First"  placeholder="Digite seu primeiro nome" />
            </div>
            
            <div className={styles.formWebLp__divBox}>
                <label>Sobrenome</label>
                <input type="text"  name="Name_Last"  placeholder="Digite seu sobrenome" />
            </div>
            

            <div className={styles.formWebLp__divBox}>
                <label> Telefone </label>
                <input type="text"  name="PhoneNumber_countrycode"  id="international_PhoneNumber_countrycode" placeholder="00&#x20;00000000" />
            </div>
          
            <div className={styles.formWebLp__divBox}>
                <label> Email </label>
                <input type="text"  name="Email"  placeholder="Digite&#x20;seu&#x20;melhor&#x20;e-mail" />
            </div>
           
            <div className={styles.formWebLp__divBox}>
                <label> Mensagem </label>
                <textarea name="MultiLine" placeholder="Deixe-nos&#x20;uma&#x20;mensagem" ></textarea>
            </div>
            
            <LoadingButton type="submit" color="primary" variant="contained" size="medium" fullWidth={true}>Enviar</LoadingButton>
        </form>
    )
}