import { Title } from '../../components/Title/Title';
import React from 'react';
import styles from './LoginForm.module.scss';
import { Input } from 'src/components/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import MachineLogin from '../../assets/images/machine-login.png';
import Logo from '../../assets/logo.jpg';
import { useContext } from 'react';
import { UserContext } from 'src/context/UserContext';
import { Navigate } from 'react-router-dom';
import { ConfirmationNumber } from '@mui/icons-material';

export const LoginRecoveryPassword = () => {
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<String>('');
  const [errorToken, setErrorToken] = React.useState<String>('');
  const [message, setMessage] = React.useState<String>('');

  const navigate = useNavigate();
  const { userLogin } = useContext(UserContext);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const token = window.localStorage.getItem('token_password');
    if (password && confirmPassword && token && email) {
      if (password === confirmPassword) {
        const fetchRecoveryPassword = fetch(
          `https://api2.nestrental.com.br/api/usuarios/reset`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              password: password,
              email: email,
              token: window.localStorage.getItem('token_password')
            })
          }
        );

        const response = await fetchRecoveryPassword;
        const json = await response.json();
        if (response.status == 200) {
          setError('');
          setMessage('Senha alterada com sucesso!');
          window.localStorage.setItem('token_password', '');
        } else if (response.status == 403) {
          setError('Email inválido!');
          setMessage('');
        }
      } else {
        setError('Senhas não correspondem!');
        setMessage('');
      }
    } else if (!token) {
      setErrorToken(`Acesse: `);
    } else if (!password) {
      setError('Preencha a nova senha.');
    } else if (!confirmPassword) {
      setError('Preencha a confirmação da nova senha.');
    } else if (!email) {
      setError('Preencha o e-mail.');
    }
  };

  return (
    <section className={styles.loginForm}>
      <div className={styles.loginForm__background}>
        <img src={MachineLogin} />
      </div>
      <div className={styles.loginForm__divForm}>
        <img src={Logo} />
        <Title>Redefina sua senha</Title>
        <form>
          <Input
            label="Email:"
            type="email"
            name="email"
            id="email"
            placeholder="Digite o e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => setEmail(e.target.value)}
            disabled={false}
            required
            error=""
          />
          <Input
            label="Nova senha:"
            type="password"
            name="password"
            id="password"
            placeholder="Digite a nova senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={(e) => setPassword(e.target.value)}
            disabled={false}
            required
            error=""
          />
          <Input
            label="Confirme sua senha:"
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Digite novamente a nova senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={(e) => setConfirmPassword(e.target.value)}
            disabled={false}
            required
            error=""
          />
        </form>

        <div className={styles.loginForm__divForm__recoveryPassword}>
          <div>
            <input
              style={{
                backgroundColor: 'rgba(18, 80, 130)',
                padding: '1rem',
                width: '100%',
                height: '50px',
                borderRadius: '9px',
                border: 'none',
                color: '#fff',
                fontSize: '1rem',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                letterSpacing: '1px',
                cursor: 'pointer',
                marginTop: '2rem',
                boxShadow: '1px 2px 5px 2px #ccc'
              }}
              type="submit"
              value="Enviar"
              onClick={handleSubmit}
            />
          </div>
        </div>
        {error && <p style={{ color: 'red', fontSize: '.9rem' }}>{error}</p>}
        {errorToken && (
          <p style={{ color: 'red', fontSize: '.9rem' }}>
            {errorToken}
            <Link to="/recuperar-senha">recuperar-senha.</Link>
          </p>
        )}

        {message && (
          <p style={{ color: 'green', fontSize: '.9rem' }}>
            {message} Faça o <a href="https://nestrental.com.br/">Login!</a>
          </p>
        )}
        <hr />
        <div className={styles.loginForm__divForm__forgetPassword}>
          <p>
            Você não tenha uma conta?
            <Link
              to="/cadastro"
              style={{ color: 'rgba(18, 80, 130, 1)', fontWeight: 'bold' }}
            >
              {' '}
              Inscreva-se
            </Link>
          </p>
          <p style={{ textAlign: 'right', marginTop: '1rem' }}>
            <Link
              to="/"
              style={{ color: 'rgba(18, 80, 130, 1)', fontWeight: 'bold' }}
            >
              Voltar ao site
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};
