import React, { useContext } from 'react';
import http from 'src/http';
import { UserContext } from './UserContext';

export type PropsContextType = {
  cep: string;
  setCep: (cep: string) => void;
  totalDays: number;
  setTotalDays: (totalDays: number) => void;
  billing: number;
  setBilling: (billing: number) => void;
  price: number;
  setPrice: (price: number) => void;
  newPrice: number;
  setNewPrice: (newPrice: number) => void;
  calculaValorDias: (totalDays: number) => void;
  startDate: string;
  endDate: string;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  nameLocataria: string;
  setNameLocataria: (name: string) => void;
  street: string;
  bairro: string;
  country: string;
  state: string;
  setStreet: (street: string) => void;
  setBairro: (bairro: string) => void;
  setCountry: (country: string) => void;
  setState: (state: string) => void;
  setIdProduct: (id: number) => void;
  contact: string;
  setContact: (contact: string) => void;
  dataCheckout: Array<{}>;
  setDataCheckout: () => void;
  login: Boolean;
  idProduct: number;
  setLogin: (login: boolean) => boolean;
  nameProduct: string;
  setNameProduct: (name: string) => void;
};
export const checkContext = React.createContext<PropsContextType>({
  cep: '',
  setCep: () => '',
  totalDays: 0,
  setTotalDays: (totalDays: number) => '',
  billing: 0,
  setBilling: () => '',
  price: 0,
  setPrice: () => 0,
  newPrice: 0,
  setNewPrice: () => 0,
  calculaValorDias: (totalDays: number) => '',
  startDate: '',
  endDate: '',
  setStartDate: () => '',
  setEndDate: () => '',
  nameLocataria: '',
  setNameLocataria: () => '',
  street: '',
  bairro: '',
  country: '',
  state: '',
  setStreet: () => '',
  setBairro: () => '',
  setCountry: () => '',
  setState: () => '',
  setIdProduct: () => '',
  contact: '',
  setContact: () => '',
  dataCheckout: [{}],
  setDataCheckout: () => '',
  login: null,
  setLogin: (login: boolean) => null,
  idProduct: 0,
  setNameProduct: (nameProduct: string) => '',
  nameProduct: ''
});

export const CheckoutContext = ({ children }: any) => {
  const [cep, setCep] = React.useState('');
  const [totalDays, setTotalDays] = React.useState<number>(0);
  const [billing, setBilling] = React.useState<number>(0);
  const [price, setPrice] = React.useState<number>(0);
  const [newPrice, setNewPrice] = React.useState<any>(0);
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [nameLocataria, setNameLocataria] = React.useState('');
  const [street, setStreet] = React.useState<string>('');
  const [bairro, setBairro] = React.useState<string>('');
  const [country, setCountry] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [contact, setContact] = React.useState<string>('');
  const [login, setLogin] = React.useState<Boolean>(false);

  //product infos
  const [idProduct, setIdProduct] = React.useState(0);
  const [nameProduct, setNameProduct] = React.useState('');

  let difference: number = 0;
  let valor: number = 0;

  function diferenceBetweenDate() {
    if (startDate != null && endDate != null) {
      let date_1 = new Date(startDate);
      let date_2 = new Date(endDate);
      if (date_1 > date_2) {
        difference = date_1.getTime() - date_2.getTime();
      } else if (date_2 > date_1) {
        difference = date_2.getTime() - date_1.getTime();
      } else if (date_1 == date_2) {
        difference = 0;
        setPrice(0);
      }
      setTotalDays(Math.ceil(difference / (1000 * 3600 * 24)));
    } else {
      difference = 0;
      valor = 0;
    }
  }

  const GetValueProduct = async (idProduct: any, totalDays: any) => {
    const fetchProduct = fetch(
      `https://api2.nestrental.com.br/api/produtos/precos/${idProduct}/${totalDays}`,
      {
        method: 'GET'
      }
    );

    const response = await fetchProduct;
    const json = await response.json();
    return json[0].valor;
  };

  const GetProduct = () => {
    http.get(`/produtos/${idProduct}`).then((response) => {
      setNameProduct(response?.data[0]?.nome ?? "");
    });
  };

  async function calculaValorDias(totalDays: number) {
    let result: number = 0;
    if (totalDays === 0) {
      setNewPrice(0);
      setPrice(0);
      return 0;
    }
    if (totalDays === 1) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 2) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 3) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 4) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 5) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 6) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 7) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 8) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 9) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 10) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 11) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 12) {
      let value = await GetValueProduct(idProduct, totalDays);

      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 13) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 14) {
      let value = await GetValueProduct(idProduct, totalDays);

      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 15) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 16) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 17) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 18) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 19) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 20) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 21) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 22) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 23) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 24) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 25) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 26) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 27) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 28) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 29) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    }
    if (totalDays === 30) {
      let value = await GetValueProduct(idProduct, totalDays);
      setNewPrice(value);
      setPrice(value - value * 0.05);
      return value;
    } else if (totalDays > 30) {
      let comp = totalDays - 30;
      let value2 = await GetValueProduct(idProduct, 30);
      result = (await calculaValorDias(comp)) + value2;
      setNewPrice(result);
      setPrice(result - result * 0.05);
      return result;
    }
    return result;
  }

  React.useEffect(() => {
    diferenceBetweenDate();
    GetProduct();
  }, [startDate, endDate, idProduct]);

  React.useEffect(() => {
    calculaValorDias(totalDays);
  }, [totalDays, idProduct]);

  return (
    <checkContext.Provider
      value={{
        cep,
        setCep,
        totalDays,
        billing,
        setBilling,
        price,
        setPrice,
        setTotalDays,
        newPrice,
        setNewPrice,
        calculaValorDias,
        startDate,
        endDate,
        setEndDate,
        setStartDate,
        nameLocataria,
        setNameLocataria,
        street,
        setStreet,
        bairro,
        setBairro,
        country,
        setCountry,
        contact,
        setContact,
        state,
        setState,
        dataCheckout: [],
        setDataCheckout: () => '',
        login,
        setLogin: () => null,
        setIdProduct,
        idProduct,
        nameProduct,
        setNameProduct
      }}
    >
      {children}
    </checkContext.Provider>
  );
};
