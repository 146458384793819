import React from 'react';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import { SubHeader } from 'src/components/SubHeader/SubHeader';
import styles from './Demonstration.module.scss';
import { dataProducts } from './DataProducts/dataProducts';
import { Link } from 'react-router-dom';
import { useMedia } from 'src/hooks/useMedia';
import { useState, useEffect } from 'react';
import { api } from 'src/api/api';


export const Demonstration = ()=>{

    const [produto, setProduto] = useState(null);

    async function carregar() {
        const { data } = await api.get(`/produtos`);
        setProduto(data)
    }


  useEffect(() => {
    carregar();
  }, []);

    const mobile = useMedia('(max-width: 768px)');
    return(
        <React.Fragment>
            <Header/>
            <SubHeader text="Demonstração"/>
            <section className={styles.demonstration}>
                <h2 className={styles.demonstration__title}>Escolha a plataforma ideal</h2>
                <div className={styles.demonstration__products}>
                    {!mobile && (
                        <div className={styles.demonstration__products__text}>
                            <ul>
                                <li style={{background: '#f0f0f0'}}>Dimensões compactas:</li>
                                <li style={{background: '#d9d9d9'}}>Altura de trabalho:</li>
                                <li style={{background: '#f0f0f0'}}>Peso equipamento: </li>
                                <li style={{background: '#d9d9d9'}}>Cap. carga plataforma: </li>
                                <li style={{background: '#f0f0f0'}}>Acionamento:</li>
                            </ul>
                        </div>
                    )}
                    
                    {dataProducts.map((product, index)=>{
                        return(
                            <div key={index} className={styles.demonstration__products__container}>
                                <div>
                                    <div  className={styles.demonstration__products__container__product}>
                                        <Link to={`/produto/${product.link}`} state={{id: product.id}} >
                                            <img src={product.image}/>
                                            <h3>{product.title}</h3>
                                        </Link>
                                    </div>
                                </div>
                                {!mobile && (
                                    <ul className={styles.demonstration__products__container__list}>
                                        <li style={{background: '#f0f0f0'}}>{product.dimension}</li>
                                        <li style={{background: '#d9d9d9'}}>{product.height}</li>
                                        <li  style={{background: '#f0f0f0'}}>{product.weight}</li>
                                        <li style={{background: '#d9d9d9'}}>{product.cap_carga}</li>
                                        <li  style={{background: '#f0f0f0'}}>{product.trigger}</li>
                                    </ul>
                                )}

                                {mobile && (
                                    <div style={{display: 'flex', width: '100%', padding: '0 1rem'}}>
                                        <ul className={styles.demonstration__products__container__list}>
                                            <li style={{background: '#f0f0f0', fontWeight: 'bold'}}>Dimensões compactas:</li>
                                            <li style={{background: '#d9d9d9', fontWeight: 'bold'}}>Altura de trabalho:</li>
                                            <li  style={{background: '#f0f0f0', fontWeight: 'bold'}}>Peso equipamento:</li>
                                            <li style={{background: '#d9d9d9', fontWeight: 'bold'}}>Capacidade de carga:</li>
                                            <li  style={{background: '#f0f0f0', fontWeight: 'bold'}}>Acionamento:</li>
                                        </ul>
                                    <ul className={styles.demonstration__products__container__list}>
                                        <li style={{background: '#f0f0f0'}}>{product.dimension}</li>
                                        <li style={{background: '#d9d9d9'}}>{product.height}</li>
                                        <li  style={{background: '#f0f0f0'}}>{product.weight}</li>
                                        <li style={{background: '#d9d9d9'}}>{product.cap_carga}</li>
                                        <li  style={{background: '#f0f0f0'}}>{product.trigger}</li>
                                    </ul>
                                    </div>
                                )}
                                
                                
                            </div>
                        )
                    })}
                </div>
                {/*<button className={styles.demonstration__btnAgendar}>
                    <a href="https://nestrental.zohobookings.com/#/customer/nest?booknow=true">Agende sua demonstração online</a>
                </button>*/}
            </section>

            <Footer/>
        </React.Fragment>
    )

}