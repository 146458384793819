import Carousel from 'react-elastic-carousel';
import styles from './Carousel.module.scss';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from 'src/api/api';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMedia } from 'src/hooks/useMedia';

export const CarouselNest = () => {
  const mobile = useMedia('(max-width: 768px)');

  const [produto, setProduto] = useState(null);

  async function carregar() {
    const { data } = await api.get(`/produtos`);
    setProduto(data);
  }

  useEffect(() => {
    carregar();
  }, []);

  return (
    <>
      {!mobile && produto ? (
        <div className={styles.carousel}>
          <Carousel
            itemsToShow={3}
            isRTL={false}
            itemsToScroll={1}
            showArrows={false}
            enableAutoPlay={true}
            autoPlaySpeed={2000}
            transitionMs={500}
            enableMouseSwipe={false}
          >
            {produto.map((item, index) => {
              return (
                <Link
                  to={`/produto/${item.identificador}`}
                  state={{ id: item.id }}
                  key={index}
                >
                  <div className={styles.carousel__item}>
                    <img
                      className={styles.carousel__item__imgDesk}
                      src={`https://api2.nestrental.com.br${item.prod_image}`}
                      alt={item.nome}
                      style={{ objectFit: 'contain' }}
                    />
                    <h4>{item.nome}</h4>
                  </div>
                </Link>
              );
            })}
          </Carousel>
        </div>
      ) : (
        mobile &&
        produto && (
          <div className={styles.carousel}>
            <Carousel
              itemsToShow={1}
              isRTL={false}
              itemsToScroll={1}
              showArrows={false}
              enableAutoPlay={true}
              autoPlaySpeed={2000}
              transitionMs={500}
              enableMouseSwipe={false}
            >
              {produto.map((item, index) => {
                return (
                  <Link
                    to={`/produto/${item.identificador}`}
                    state={{ id: item.id }}
                    key={index}
                  >
                    <div className={styles.carousel__item}>
                      <img
                        className={styles.carousel__item__imgMob}
                        src={`https://api2.nestrental.com.br${item.prod_image}`}
                        alt={item.nome}
                      />
                      <h4>{item.nome}</h4>
                    </div>
                  </Link>
                );
              })}
            </Carousel>
          </div>
        )
      )}
    </>
  );
};
