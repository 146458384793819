import styles from './SubHeader.module.scss';

export const SubHeader = (props)=>{
    return (
        <div className={styles.subheader}>
            <h1>
                {props.text}
            </h1>
        </div>
    )
}