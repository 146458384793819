import { Title } from '../../components/Title/Title';
import React from 'react';
import styles from './LoginForm.module.scss';
import { Input } from 'src/components/Input/Input';
import { useForm } from 'src/hooks/useForm';
import { Link } from 'react-router-dom';
import MachineLogin from '../../assets/images/machine-login.png';
import Logo from '../../assets/logo.jpg';
import { useContext } from 'react';
import { UserContext } from 'src/context/UserContext';
import { TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
export const LoginForgotPassword = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<String>('');
  const [message, setMessage] = React.useState<String>('');

  const { userLogin, recoveryPassword } = useContext(UserContext);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (email) {
      const fetchRecoveryPassword = fetch(
        `https://api2.nestrental.com.br/api/usuarios/recovery`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email,
            url: 'https://nestrental.com.br/redefinir-senha'
          })
        }
      );

      const response = await fetchRecoveryPassword;
      if (response.status == 200) {
        const json = await response.json();
        setMessage(
          'Email enviado com sucesso. Por favor, Verifique sua caixa de email!'
        );
        setError('');
        window.localStorage.setItem('token_password', json.token);
      } else if (response.status == 403) {
        setError('Este usuário não existe!');
        setMessage('');
      }
    }
  };

  return (
    <section className={styles.loginForm}>
      <div className={styles.loginForm__background}>
        <img src={MachineLogin} />
      </div>
      <div className={styles.loginForm__divForm}>
        <img src={Logo} />
        <Title>Recupere sua senha</Title>
        <form>
          <TextField
            label="Email"
            type="text"
            name="user_email"
            id="userEmail"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={false}
            fullWidth={true}
            color="primary"
            required={true}
          />
          <div style={{ marginTop: '1rem' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth={true}
              onClick={handleSubmit}
            >
              Enviar
            </LoadingButton>
          </div>
        </form>
        {error && <p style={{ color: 'red', fontSize: '.9rem' }}>{error}</p>}

        {message && (
          <p style={{ color: 'green', fontSize: '.9rem' }}>{message}</p>
        )}

        <hr />
        <div className={styles.loginForm__divForm__forgetPassword}>
          <p>
            Você não tenha uma conta?
            <Link
              to="/cadastro"
              style={{ color: 'rgba(18, 80, 130, 1)', fontWeight: 'bold' }}
            >
              {' '}
              Inscreva-se
            </Link>
          </p>
          <p style={{ textAlign: 'right', marginTop: '1rem' }}>
            <Link
              to="/"
              style={{ color: 'rgba(18, 80, 130, 1)', fontWeight: 'bold' }}
            >
              Voltar ao site
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};
