
import Ecolift50 from '../../../assets/images/demonstration/ecolift-50.jpg';
import Ecolift70 from '../../../assets/images/demonstration/ecolift-70.jpg';
import EcoliftP from '../../../assets/images/demonstration/1030p (1).png';
import EcoliftPDuo from '../../../assets/images/demonstration/1030p-duo.jpg';
import LiftPodFt140 from '../../../assets/images/demonstration/liftpod-ft140.jpg';
import ToucanT26E from '../../../assets/images/demonstration/toucan-t26e.png';
import Ecolift30Am from '../../../assets/images/demonstration/30am.jpg';

export const dataProducts = [
    {
        image: Ecolift50,
        title: 'ECOLIFT 50',
        dimension: '0,70 x 0,99m',
        height: '3,33m',
        trigger: 'Manual',
        weight: '180kg',
        cap_carga: '150kg',
        link: 'ecolift-50',
        id: 1
    },
    {
        image: Ecolift70,
        title: 'ECOLIFT 70',
        dimension: '0,70 x 1,28m',
        height: '4,20m',
        trigger: 'Manual',
        weight: '305kg',
        cap_carga: '150kg',
        link: 'ecolift-70',
        id: 4
    },
    {
        image: EcoliftP,
        title: '1030P',
        dimension: '0,76 x 1,60m',
        height: '5,10m',
        trigger: 'Elétrico',
        weight: '342kg',
        cap_carga: '250kg',
        link: '1030p',
        id: 14
    },
    {
        image: EcoliftPDuo,
        title: '1030p DUO',
        dimension: '0,78 x 1,85m',
        height: '5,10m',
        trigger: 'Elétrico',
        weight: '305kg',
        cap_carga: '250kg',
        link: '1030p-duo',
        id: 24
    },
    {
        image: LiftPodFt140,
        title: 'LIFTPOD FT 140',
        dimension: '1,48 x 1,63m',
        height: '6,00m',
        trigger: 'Elétrico',
        weight: '148kg',
        cap_carga: '150kg',
        link: 'liftpod-ft-140',
        id: 34
    },
    {
        image: ToucanT26E,
        title: 'TOUCAN T26E',
        dimension: '0,99 x 2,82m',
        height: '10m',
        trigger: 'Elétrico',
        weight: '3.310kg',
        cap_carga: '227kg',
        link: 'toucant-26e',
        id: 44
    },
    {
        image: Ecolift30Am,
        title: '30AM',
        dimension: '0,74 x 1,32m',
        height: '11m',
        trigger: 'Elétrico',
        weight: '504kg',
        cap_carga: '136kg',
        link: '30am',
        id: 54
    },

]

