import React from 'react';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import styles from './Thanks.module.scss';
import {AiOutlineCheckCircle} from 'react-icons/ai';
import { useNavigate} from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import {useContext} from 'react'
import { isNamedExportBindings, isNamespaceExport } from 'typescript';
import ZohoSalesIQ from 'src/components/ChatZoho/ChatZoho';


export const Thanks = ()=>{

    const nameUser = window.localStorage.getItem('name_user');

    return(
        <>
            <Header/>
            <section style={{
                backgroundColor: "#125082",
                padding: "1rem 5rem",
                height: "150px",
                display: "flex",
                alignItems: "center"
            }}>
            <h1 style={{
                color: "#fff",
            }}>
                Aluguel concluído
            </h1>
            </section>
              
            <section className={styles.thanks}>
                <AiOutlineCheckCircle size={200} color="#44963b"/>
                <p className={styles.thanks__text}>
                    {nameUser.toUpperCase()}, sabemos que a escolha do cliente é uma decisão pessoal e inquestionável, 
                    por isso nós, da Nest Rental, agradecemos pela parceria e preferência por
                    uma de nossas plataformas elevatórias JLG. 
                    É um privilégio atender sua empresa e um prazer voltar a revê-lo utilizando,
                    numa próxima oportunidade, uma de nossas práticas e seguras plataformas
                    elevatórias para trabalhos em altura. 
                    Atenciosamente,
                    Paulo.
                </p>
                <p className={styles.thanks__login}>Faça login e verifique o status do seu pedido: <Link to="/login">Login</Link></p>
            </section>
            <Footer/>
        </>
    )
}