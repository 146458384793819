import styles from './Solutions.module.scss';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import { Products } from './Products/Products';
import { SubHeader } from 'src/components/SubHeader/SubHeader';
import React from 'react';
import { api } from 'src/api/api';
import ZohoSalesIQ from 'src/components/ChatZoho/ChatZoho';

export const Solutions = () => {


  return (
    <>
      <Header/>
        <SubHeader text="Nossas soluções"/>
        <section className={styles.solutions}>
          <Products/>
        </section>
      <Footer/>
    </>
  )
}

