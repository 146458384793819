


import React from "react";

export default function ZohoSalesIQ() {
    React.useEffect(() => {
        if(window.location.pathname.includes('/dashboard') === false){
            const url = 'https://salesiq.zoho.com/widget';
            const widgetCode = 'd2206add6a3216dd5d4f90c60627652164b97e99a17b8e4262a29ae85a72d27e94616b5165678b017b1ba63892ac932a';
            const script = document.createElement('script');
            script.setAttribute("type", "text/javascript");
            script.setAttribute("id", "scriptid");
            let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
            script.appendChild(document.createTextNode(code));
            document.body.appendChild(script);
            return () => {
                document.getElementById('scriptid').remove();
                document.getElementById('zsiqscript').remove();
            }
        }
      
    },[window.location.pathname])
    
    const showChat = () => {
        document.getElementById('zsiq_float').classList.remove('d-none');
        document.getElementById('zsiq_agtpic').click();
    }
    
    return (
        <>
        <style>{`
            .zsiq_theme1.zsiq_floatmain {opacity: 0 !important; pointer-events :none; display:none !important;}
            .siqanim{
                transform: scale(1)!important;
            }
        `}
        </style>
            <div className="col-md-3" onClick={showChat} role="button">
            </div>
        </>
    )
}