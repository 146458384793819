import { useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import { checkContext } from 'src/context/CheckoutContext';
import React from 'react';
import {MdOutlineDashboard} from 'react-icons/md';
import {FiSettings} from 'react-icons/fi'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';




const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const user = {
    name: 'Fernanda Alves',
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: 'Administrador'
  };

  const {
    emailUser,
    passwordUser,
    levelUser,
    usuario
  } = useContext(UserContext);


  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const navigate = useNavigate();

  

    const {login, setLogin} = React.useContext(checkContext);
    const handleSubmit = (e: any)=>{
        e.preventDefault();
        window.localStorage.clear()
        setLogin(false);
        window.location.href = 'https://nestrental.com.br'
    }


  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={emailUser} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{emailUser}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {levelUser}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={emailUser} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{usuario?.razao_social}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {levelUser}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">


        {usuario?.id_perfil === 2?
        <a href="https://nestrental.com.br/dashboard/cliente/meus-pedidos" 
          style={
            {
              display: 'flex',
              textDecoration:'none',
              alignItems: 'center',
              padding: '1rem',
              gridGap: '5px',
              color: '#125082'
              }
            }
          >
          <MdOutlineDashboard style={{color:"#125082", width: '20px', height: '20px'}}/>
          <ListItemText primary="Painel" />
        </a>
          :
        <a href="https://nestrental.com.br/dashboard/admin/geral" 
        style={
          {
            display: 'flex',
            textDecoration:'none',
            alignItems: 'center',
            padding: '1rem',
            gridGap: '5px',
            color: '#125082'
            }
          }>
           <MdOutlineDashboard style={{color:"#125082", width: '20px', height: '20px'}}/>
          <ListItemText primary="Painel" />
        </a>

        }


        {usuario?.id_perfil == 2 ?
          <a href="https://nestrental.com.br/dashboard/perfil/cliente/configuracoes" 
            style={
              {
                display: 'flex',
                textDecoration:'none',
                alignItems: 'center',
                padding: '1rem',
                gridGap: '5px',
                color: '#125082'
                }
              }
          >
            <FiSettings style={{color:"#125082", width: '20px', height: '20px'}}/>
            <ListItemText primary="Configurações da conta" />
          </a>
          :
          <a href="https://nestrental.com.br/dashboard/perfil/admin/configuracoes" 
            style={
              {
                display: 'flex',
                textDecoration:'none',
                alignItems: 'center',
                padding: '1rem', gridGap: '5px',
                color: '#125082'
                }
              }
          >
            <FiSettings style={{color:"#125082", width: '20px', height: '20px'}} />
            <ListItemText primary="Configurações da conta" />
          </a>
        }

        
        
         


        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSubmit}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sair
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
