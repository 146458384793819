import { Box, Typography } from '@material-ui/core';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import Header from 'src/components/Header/Header';
import styles from './Privacy.module.scss'



const Privacy = ()=>{
    return(
        <>
            <Header/>
            <Container> 
                <Box className={styles.box}>
                <h1 
                    style={{fontSize: '2rem', fontWeight: 'bold', textTransform: 'uppercase', marginTop: '2rem'}}
                >
                    Política de Privacidade
                </h1>
                    <p>
                        A Nest Rental valoriza seus clientes e parceiros, estando comprometida
                        com a proteção e privacidade dos seus dados pessoais. Por isso, nos preocupamos
                        em esclarecer e divulgar nossa política de privacidade de dados, a qual descreve
                        as diretrizes e práticas da Nest Rental no que se refere ao armazenamento, uso,
                        conservação e divulgação das informações pessoas coletadas em nossos cainais de relacionamento.
                        <p>
                            Essa política explica como nós usamos seus dados pessoais. Queremos 
                            ajudar você a entender como nós tratamos os seus dados, visando que você
                            possa tomar decisões livres, informadas, bem como controlar a sua
                            informação. Pedimos gentilmente que você leia a mesma com atenção.
                        </p>
                        <p>Esta política permitirá que você saiba:</p>
                        <ul style={{fontWeight: 'bold'}}>
                            <li>Quem somos nós?</li>
                            <li>Quais dados pessoais nós coletamos e como fazemos isso?</li>
                            <li>Para que utilizamos os dados que recolhemos?</li>
                            <li>Com quem compartilhamos os dados?</li>
                            <li>Onde armazenamos e como protegemos as informações recolhidas?</li>
                            <li>Quais são seus direitos?</li>
                            <li>Como iremos notificá-lo em caso de mudanças à essa política?</li>
                        </ul>
                        <p>
                            Caso você tenha alguma questão adicional sobre o tratamento dos seus
                            dados, não hesite em realizar contato com nossos canais de atendimento,
                            disponibilizados através de nosso site <a href="https://nestrental.com.br">nestrental.com.br</a>.
                        </p>
                        <p>
                            Ao navegar em nosso website, o usuário declara que fez a leitura 
                            completa e atenta da presente Política de Privacidade, estando 
                            plenamente ciente, conferindo assim, sua livre e expressa concordância 
                            com os termos aqui estipulados.
                        </p>
                    </p>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold', textTransform: 'initial'}}
                        >
                        Quem somos nós?
                        </h1>
                        <p>Os seus dados pessoais serão controlados pela sociedade indicada abaixo:</p>
                        <p><b>NEST LOCAÇÃO E REVENDA DE MAQUINAS LTDA</b>, pessoa jurídica de direito 
                            privado, inscrita no CNPJ sob o no 30.224.282/0001-71, com sede à AV. 
                            ROQUE PETRONI JÚNIOR, 1226 – SÃO PAULO - SP. 
                        </p>
                        <p>
                            Sempre que essa política utilizar as expressões “Nest Rental”, 
                            Empresa” ou “nós”, ela está se referindo à sociedade descrita acima.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                        Quais dados pessoais nós coletamos e como fazemos isso?
                        </h1>
                        <p>
                            Os seus dados pessoais podem ser coletados de três formas: a) Dados fornecidos espontaneamente pelo usuário;
                        </p>
                        <p>
                            Os dados são coletados quando inseridos ou submetidos voluntariamente pelo usuário para acessar determinadas 
                            funcionalidades disponibilizadas em nosso web site. Nesse caso, serão coletados os seguintes dados:
                        </p>
                        <ul>
                            <li><b>Dados de contato:</b> (que usualmente incluem: e-mail, telefone celular, endereço, dentre outros);</li>
                            <li><b>Dados de identificação:</b> (que usualmente incluem: nome completo, dentre outros);</li>
                        </ul>
                        <p>
                            Caso seja necessário coletar dados de crianças ou adolescentes que possuam vínculo parental com o usuário, 
                            ou que estejam sob a sua responsabilidade legal, será requerido o expresso consentimento do usuário para 
                            realização das operações de tratamento desses dados;
                        </p>
                        <p>
                            Cabe ao usuário o dever de prestar informações corretas e atualizadas. Não somos responsáveis pela precisão,
                            veracidade ou falta dela nas informações providas pelo usuário.
                        </p>
                        <ul>
                            <li><b>Dados fornecidos por terceiros:</b></li>
                        </ul>
                        <p>
                            No que é permitido por Lei, a Nest Rental também poderá obter dados pessoais sobre você e acrescentá-los 
                            aos demais dados pessoais que você nos disponibilize, tanto de entidades afiliadas, parceiros comerciais
                            e outras fontes de terceiros independentes, quanto de bases de dados públicas, informações recolhidas 
                            durante uma conversa telefônica junto ao Serviço de Atendimento ao Cliente (SAC) e/ou através de aplicativos 
                            em geral. Todo e qualquer dado pessoal obtido pela Nest Rental, será tratado em conformidade com as 
                            disposições desta Política de Privacidade.
                        </p>
                        <ul>
                            <li><b>Dados coletados automaticamente:</b></li>
                        </ul>
                        <p>
                            São coletados automaticamente dados dos usuários por meio da utilização de cookies. 
                            Todas as questões relacionadas a tais dados podem ser consultadas no nosso Aviso de Cookies que está disponível no site Nest Rental.
                        </p>
                    </div>


                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                            Com quem compartilhamos as informações recolhidas?
                        </h1>
                        <p>
                            Os seus dados pessoais somente serão compartilhados com terceiros nas seguintes hipóteses: 
                            a) em favor de prestadores de serviços para a gestão das suas solicitações e processamento dos 
                            seus pagamentos com empresas específicas para tais fins, tais como: cartões de crédito, análise de 
                            crédito, serviço de atendimento ao cliente e prevenção de fraudes;  b) recebimento e avaliação de 
                            denúncias formuladas por meio do Canal de Denúncias; 
                        </p>
                        <p>
                            Nos casos aplicáveis a todos os prestadores de serviços e parceiros comerciais da Nest Rental, 
                            estão sujeitos a rigorosos contratos que proíbem a utilização ou divulgação não autorizada dos 
                            dados pessoais aos quais têm acesso.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                            Onde armazenamos e como protegemos as informações recolhidas?
                        </h1>
                        <p>
                            Nós trabalhamos arduamente para proteger os nossos usuários contra acessos não autorizados, 
                            alterações, vazamentos ou destruição de seus dados pessoais. Para tanto, adotamos os níveis legalmente
                            exigíveis para impedir ou minimizar incidentes de privacidade, mas é preciso saber que as medidas 
                            de segurança relativas à Internet não são integralmente infalíveis.
                        </p>
                        <p>
                            Monitoramos regularmente os nossos sistemas visando identificar possíveis vulnerabilidades ou ataques
                            e revisamos rotineiramente nossos procedimentos de coleta, armazenamento e processamento de dados para
                            melhoria contínua da eficiência das nossas medidas de segurança. Os dados pessoais serão tratados com o
                            nível de proteção legalmente exigível para garantir sua segurança e evitar alterações, perdas, tratamentos 
                            ou acessos irregulares por pessoas não autorizadas.
                        </p>
                        <p>
                            Podemos suspender (parcial ou integralmente) os nossos serviços sem aviso prévio em caso de suspeita de quebra de segurança.
                        </p>
                        <p>
                            Adicionalmente, empregamos esforços na conscientização de nossos colaboradores sobre a importância dos temas relacionados à
                            proteção de dados, no intuito de mantê-los atualizados quanto às melhores práticas e quanto à importância no controle de
                            acesso e dever de sigilo que deve ser aplicado a estes dados.
                        </p>
                        <p>
                            Armazenamos as informações pelo período necessário para que todas as finalidades previstas nesta Política sejam atingidas 
                            integralmente.
                        </p>
                        <p>
                            Os dados referentes aos registros de acesso aos serviços providos pela Nest Rental estarão armazenados em ambiente seguro 
                            e controlado pelo prazo mínimo de 6 (seis) meses, em conformidade com o art. 15 do Marco Civil da Internet (Lei 12.965/14),
                            observado o estado da técnica disponível.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                            Quais são seus direitos?
                        </h1>
                        <p>
                            Você poderá, nos termos da legislação aplicável: a) solicitar a exibição ou retificação de seus dados pessoais; 
                            b) requerer a limitação do uso de seus dados pessoais; c) manifestar sua oposição ao uso de seus dados pessoais; 
                            d) revogar o consentimento concedido ou e) solicitar a exclusão de seus dados pessoais coletados e registrados
                            pela Nest Rental por meio dos nossos canais de atendimento.
                        </p>
                        <p>
                            A Nest Rental, poderá reter os referidos dados pessoais em seus sistemas nas hipóteses autorizadas por lei; 
                            todavia, em qualquer caso, as informações de um usuário não serão retiradas imediatamente dos nossos 
                            arquivos por motivos legais e técnicos, incluindo-se os sistemas de suporte de segurança. Portanto,
                            embora a Nest Rental, comprometa-se a empregar seus melhores esforços para que todos os dados pessoais 
                            sejam definitivamente eliminados pelo período de tempo previsto em Lei.
                        </p>
                    </div>


                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                            Como iremos notificá-lo em caso de mudanças à essa política?
                        </h1>
                        <p>
                            A Nest Rental, poderá atualizar essa Política de Privacidade periodicamente, sendo que a versão em vigor será sempre a mais recente.
                        </p>
                        <p>
                            Se fizermos alguma alteração na política em termos materiais, poderemos disponibilizar um aviso no nosso website,  
                            ou lhe enviar um e-mail, juntamente com a Política de Privacidade atualizada.
                        </p>
                        
                        <p>
                            Para verificar a data da versão em vigor, verifique a “Data de atualização” no início desse documento.
                        </p>
                    </div>


                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold', textTransform: 'uppercase'}}
                        >
                            POLÍTICA DE COOKIES
                        </h1>
                        <p>
                            Nosso site utiliza cookies e nossa Política de Cookies informará o que são cookies, 
                            como podemos usá-los, os tipos de cookies que usamos e como você pode recusá-los.
                        </p>

                        <p>
                            Esta política abrange:
                        </p>
                        <ul>
                            <li>O que são cookies;</li>
                            <li>Como usamos cookies;</li>
                            <li>Posso dizer não a eles?</li>
                            <li>Cookies que definimos;</li>
                            <li>Cookies de terceiros;</li>
                            <li>Como faço para redefinir ou apagar cookies?</li>
                            <li>Como nos contatar?;</li>
                            <li>Alterações da nossa política de cookies.</li>
                        </ul>
                        <p>
                            Este Manual de Cookies deve ser lido em conjunto com a nossa Política de Privacidade.
                        </p>
                    </div>


                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                            O que são cookies?
                        </h1>
                        <p>
                            Cookies são pequenos arquivos de texto que podem definir preferências, dependendo de suas 
                            escolhas no seu dispositivo, quando visita o nosso website. Um cookie normalmente é um arquivo
                            de texto contendo um número de identificação anônima, exclusiva e arbitrária. Quando você voltar
                            a visitar o nosso site, ele irá procurar os cookies em seu dispositivo para ver se você já nos
                            visitou anteriormente.
                        </p>
                        <p>
                            Existem duas grandes categorias de cookies: 
                        </p>
                        <p>
                            <b>Cookies persistentes: </b>estes permanecem em seu dispositivo, devendo ser removidos manualmente ou automaticamente;
                        </p>
                        <p>
                            <b>Cookies de sessão: </b>estes permanecem em seu dispositivo durante a navegação, sendo excluídos automaticamente ao fechar navegador.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                        Como usamos cookies?
                        </h1>
                        <p>
                            Nós usamos cookies para personalizar o conteúdo que você recebe do nosso site, por exemplo:
                        </p>
                        <ul>
                            <li>Lembrando de suas preferências, escolhas de privacidade / consentimento, a sua edição, configuração ou linguagem escolhida etc.;</li>
                            <li>Tornar a navegação mais fácil, e que nossas páginas sejam exibidas corretamente;</li>
                            <li>Identificar se você vem do site dos nossos parceiros;</li>
                            <li>Analisar o desempenho do nosso site, com base em dados anônimos 
                                relacionados com a sua navegação (por exemplo: páginas visitadas, número de visitas, etc.). 
                                Utilizamos o Google Analytics para tal finalidade;
                            </li>
                            <li>Adequar a publicidade e banners que você vê em nossos sites. Exemplo: se você visitar o nosso site e 
                                nos permitir incluir um cookie de publicidade comportamental, você poderá ver alguns de nossos anúncios
                                quando visitar algum outro site relacionado. Neste caso, o cookie poderá ter sido utilizado para adaptar
                                o banner que você vê.
                            </li>
                        </ul>
                        <p>
                            Os cookies utilizados neste site são concebidos para serem utilizados apenas por nós ou pelas partes que trabalham em nosso nome.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                        Posso dizer não a eles?
                        </h1>
                        <p>
                            Você pode se recusar a aceitar alguns ou todos os cookies, bastando <b>desativar</b> as configurações do seu navegador que lhe permite fazer isso.
                        </p>
                        <p>
                            Você precisa estar ciente de que embora você possa excluir todos os cookies, você poderá não mais ser 
                            capaz de acessar a determinadas partes do nosso site, bem como podemos entender assim que você nunca nos 
                            visitou antes e, portanto, servir-lhe com o nosso Aviso de Cookies novamente.
                        </p>
                    </div>


                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold', textTransform: 'uppercase'}}
                        >
                        COOKIES QUE DEFINIMOS
                        </h1>
                        <p>
                            Os cookies permitidos serão definidos em nota técnica ser publicada pela área de TI.   
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                        Cookies de terceiros
                        </h1>

                        <p>
                            Em alguns casos especiais, também usamos cookies fornecidos por terceiros confiáveis. A seção a
                            seguir, detalha quais cookies de terceiros você pode encontrar através deste site. 
                        </p>

                        <p>
                            Este site usa a ferramenta <b>Google Analytics,</b> que é uma das soluções de análise mais difundidas
                            e confiáveis da Web, a qual nos ajuda a entender como você usa o site e como podemos melhorar sua experiência.  
                        </p>

                        <p>
                            Esses cookies podem rastrear itens como por exemplo, quanto tempo você gasta no site e as 
                            páginas visitadas, para que possamos assim continuar produzindo conteúdo atrativo. Para mais informações 
                            sobre cookies do <b>Google Analytics,</b> consulte a página oficial da ferramenta/serviço.   
                        </p>

                        <p>
                        Periodicamente, testamos novos recursos e fazemos alterações sutis na maneira como o site se apresenta. Quando ainda estamos testando novos recursos, esses cookies podem ser usados para garantir que você receba uma experiência consistente enquanto estiver no site, enquanto entendemos quais otimizações os nossos usuários mais apreciam.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold', textTransform: 'initial'}}
                        >
                        Como nos contatar?
                        </h1>
                        <p>
                            Use os canais apropriados de contato (e-mail, telefone ou correio) caso deseje exercer quaisquer dos seus direitos sobre seus dados.
                        </p>

                        <p>
                        Caso tenha quaisquer outras questões relacionadas ao uso de suas informações, gentileza enviar um e-mail para: <b>encarregado@mills.com.br</b> que nosso encarregado de dados, Cauê Soto, retornará conforme os prazos e direitos estabelecidos na Lei 13.709/2018.
                        </p>
                    </div>

                    <div style={{marginTop: '3rem'}}>
                        <h1 
                            style={{fontSize: '2rem', fontWeight: 'bold',  textTransform: 'initial'}}
                        >
                        Alterações da nossa política de cookies
                        </h1>
                        <p>
                        A Nest Rental poderá atualizar esta política a qualquer momento, portanto, pedimos que verifique esta página regularmente.
                        </p>
                    </div>
                </Box>
            </Container>
           <Footer/>
        </>
    )
}

export default Privacy;