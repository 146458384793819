import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Rent.module.scss';
import { useContext } from 'react';
import { checkContext } from '../../context/CheckoutContext';
import { MessageError } from 'src/components/MessageError/MessageError';
import SearchCep from 'src/helpers/SearchCep';
import { MdLocalShipping } from 'react-icons/md';
import {LoadingButton} from '@mui/lab'
import http from 'src/http';
import { UserContext } from 'src/context/UserContext';

const Rent = ({valor, nome, id}) => {

    const [log, setLog] = React.useState('');
    const [errorCep, setErrorCep] = React.useState('');
    const [error, setError] = React.useState(['']);
    const [dateMin, setDateMin] = React.useState('');

    const navigate = useNavigate();


    const {
        cep,
        setCep,
        price,
        billing,
        setBilling,
        totalDays,
        newPrice,
        setEndDate,
        setStartDate,
        setBairro,
        setStreet,
        setState,
        setCountry,
        setIdProduct,
        idProduct
    } = useContext(checkContext);



    const {usuario} = useContext(UserContext)


    const [fretes, setFretes] = React.useState([]);

    /*Verifica se o CEP está dentro do frete*/
    async function VerifyCep(){
        http.get('/fretes')
            .then(response=>{
                setFretes(response.data)
                
            })

        if(cep.length === 8){
            const json = await SearchCep(cep)
            const faixaCep = (json.cep).split('-', 1);
            const formatFaixaCep = faixaCep[0];
            setStreet(json.logradouro);
            setBairro(json.bairro);
            setCountry(json.localidade)
            setState(json.uf)
            setLog(json.logradouro + ', ' + json.bairro + ', ' + json.localidade + ', ' + json.uf)
            
            
            if(formatFaixaCep >= fretes[0].cep_inicio && formatFaixaCep <= fretes[0].cep_fim && idProduct == 44){
                setBilling(fretes[0].valor);
                setError([""])
                setErrorCep('')
            }

            else if(formatFaixaCep >= fretes[1].cep_inicio && formatFaixaCep <= fretes[1].cep_fim && idProduct == 44){
                setBilling(fretes[1].valor);
                setError([""])
                setErrorCep('')
            }

            else if(formatFaixaCep >= fretes[2].cep_inicio && formatFaixaCep <= fretes[2].cep_fim && idProduct == 44){
                setBilling(fretes[2].valor);        
                setError([""])
                setErrorCep('')
            }

            else if(formatFaixaCep >= fretes[3].cep_inicio && formatFaixaCep <= fretes[3].cep_fim && idProduct == 44){
                setBilling(fretes[3].valor);
                setError([""])
                setErrorCep('')
            }

            else if(formatFaixaCep >= fretes[4].cep_inicio && formatFaixaCep <= fretes[4].cep_fim && idProduct == 44){
                setBilling(fretes[4].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[5].cep_inicio && formatFaixaCep <= fretes[5].cep_fim && idProduct == 44){
                setBilling(fretes[5].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[6].cep_inicio && formatFaixaCep <= fretes[6].cep_fim && idProduct != 44){
                setBilling(fretes[6].valor);
                setError([""])
                setErrorCep('')
            }
             else if(formatFaixaCep >= fretes[7].cep_inicio && formatFaixaCep <= fretes[7].cep_fim && idProduct != 44){
                setBilling(fretes[7].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[8].cep_inicio && formatFaixaCep <= fretes[8].cep_fim && idProduct != 44){
                setBilling(fretes[8].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[9].cep_inicio && formatFaixaCep <= fretes[9].cep_fim && idProduct != 44){
                setBilling(fretes[9].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[10].cep_inicio && formatFaixaCep <= fretes[10].cep_fim && idProduct != 44){
                setBilling(fretes[10].valor);
                setError([""])
                setErrorCep('')
            }
            else if(formatFaixaCep >= fretes[11].cep_inicio && formatFaixaCep <= fretes[11].cep_fim && idProduct != 44){
                setBilling(fretes[11].valor);
                setError([""])
                setErrorCep('')
            }
            else{
                setError(["Área fora de cobertura de nossos serviços."])
                setErrorCep("Área fora de cobertura de nossos serviços.")
            }
        }else if(cep.length > 1 && cep.length < 8 || cep.length > 8){
            setLog('');
            setBilling(0)
            setError(["CEP inválido."])
            setErrorCep('CEP inválido.')
        }else if(cep.length == 0){
            setError([""])
        }
        
        
    }


    /*Preenche os dados de aluguel e segue para checkout*/
    function handleSubmit(e: any){
        e.preventDefault();
        if(!cep){
            setError(["Preencha um CEP"])
        }
        else if(totalDays === 0){
            setError(["Nenhum período de aluguel selecionado"])
        }
        else if(errorCep === 'CEP inválido.'){
            setError(["CEP inválido."])
        }else if(errorCep === 'Área fora de cobertura de nossos serviços.'){
            setError(["Área fora de cobertura de nossos serviços."])

        }
        else{
            navigate('/checkout', {state: {name: nome, id_produto: id}})
        }
    }

    //Bloqueia dias anteriores ao dia atual e os próximos dois dias
  function blockBeforeTwoDays(){
    var today = new Date();                   
    today.setDate(today.getDate()); 
    var l = today.toISOString().split('T')[0]; 
    setDateMin(l);
  }

    
    React.useEffect(()=>{
        VerifyCep();
    }, [cep && cep.length === 8])

    React.useEffect(()=>{
        blockBeforeTwoDays();
        setIdProduct(id)        
    
    }, [])


    


   


  return (
    <div className={styles.rent}>
        <p className={styles.rent__title}>
        a partir de
            <label style={{fontSize: "2rem"}}> {valor? valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}): ''}</label>
        </p>
        <p className={styles.rent__title}>Escolha o período de locação</p>
        <form className={styles.rent__form}>
            <div className={styles.rent__form__date}>
                <div>
                    <label htmlFor="start">Início</label>
                    <input 
                        type="date"
                        name="start"
                        min={dateMin}
                        required={true}
                        onChange={(e)=>setStartDate(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="end">Devolução</label>
                    <input 
                        type="date" 
                        name="end" 
                        required={true}
                        min={dateMin}
                        onChange={(e)=>setEndDate(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.rent__form__calc}>
                <label style={{display: 'block'}}>Calcular o frete (endereço de entrega):</label>
                <input 
                    type="number" 
                    step="0.01"
                    style={{width: '60%'}}
                    placeholder='Digite o CEP de entrega'
                    onChange={(e)=>setCep(e.target.value)}
                />
            </div>

            <div style={{width: '100%', height: '1px', background: '#ccc', marginTop: '5px'}}></div>

            {log?
                <div style={{display: 'grid', gridTemplateColumns: '1.2fr 6fr', alignItems: 'center', width: 'max-content'}}>
                    <MdLocalShipping style={{width: '40px', height: '40px'}}/>
                    <p style={{borderBottom: '1px solid #ccc'}}>{log}</p>
                </div>
                : 
                <></>
            }
            
            <div>
                <p style={{fontWeight: 'bold'}}>Resumo do pedido:</p>
                <label >Quantidade de dias: {totalDays}</label>
                <p>Frete (ida e volta): {billing? billing.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}): 0}</p>
                <p>Aluguel: {usuario?.id_perfil === 3 ? newPrice.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                            : 
                            price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                            }
                  <span>
                  {price != 0 && usuario?.id_perfil != 3 ? <span style={{fontSize: '.8rem', color: 'green'}}> (5% de desconto)</span>: ''}
                  </span>
                </p>
                <p className={styles.rent__price}>
                    TOTAL: {usuario?.id_perfil === 3 ? (newPrice+billing).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}):
                    (price+billing).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} 
                </p>
            </div>
            <LoadingButton
                type="submit"
                color="success"
                variant="contained"
                fullWidth={true}
                size="large"
                onClick={(e)=>handleSubmit(e)}
            >
                Alugar
            </LoadingButton>
        </form>

        {error &&
            <MessageError message={error}/>
        }
        
    </div> 
  )
}

export default Rent;
