import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from './layouts/SidebarLayout';
import BaseLayout from './layouts/BaseLayout';
import SuspenseLoader from './components/SuspenseLoader';
import SingleProduct from './pages/singleProduct/SingleProduct';
import { Checkout } from './pages/Checkout/Checkout';
import { LoginForm } from './pages/Login/LoginForm';
import { LoginCreate } from './pages/Login/LoginCreate';
import  Home from './pages/Home/Home';
import { Demonstration } from './pages/Demonstration/Demonstration';
import { Thanks } from './pages/Thanks/Thanks';
import { Solutions } from './pages/Solutions/Solutions';
import { LoginForgotPassword } from './pages/Login/LoginForgotPassword';
import { LoginRecoveryPassword } from './pages/Login/LoginRecoveryPassword';
import { ProtectedRoute } from './helpers/ProtectedRoute';
import { UserStorage } from './context/UserContext';
import { ListProductsModal } from './components/Modals/ListProductsModal/ListProductsModal';
import LpNest from './pages/LpNest/LpNest';
import ZohoSalesIQ from './components/ChatZoho/ChatZoho';
import { WhatsApp } from '@mui/icons-material';
import WhatsappFloat from './components/WhatsappFloat/WhatsappFloat';
import Privacy from './pages/privacy/Privacy';



const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Overview = Loader(lazy(() => import('./content/dashboards/Overview')));

const RegisterCategory = Loader(
  lazy(() => import('src/content/applications/Category'))
);

const RegisterProduct = Loader(
  lazy(() => import('./content/applications/Products'))
);

const Budget = Loader(
  lazy(() => import('./content/applications/Budgets'))
);
const Clients = Loader(
  lazy(() => import('./content/applications/Clients'))
);
const Devolution = Loader(
  lazy(() => import('./content/applications/Devolution'))
);

const Ead = Loader(
  lazy(() => import('./content/applications/Ead'))
);
const Ship = Loader(
  lazy(() => import('./content/applications/Ship'))
);

const Orders = Loader(
  lazy(() => import('./content/applications/Orders'))
);

const OrdersClient = Loader(
  lazy(() => import('./content/applications/OrdersClient'))
);
const UserProfile = Loader(
  lazy(() => import('./content/applications/Users/profile/index'))
);

const UserSettings = Loader(
  lazy(() => import('./content/applications/Users/settings/index'))
);

const Brands = Loader(
  lazy(() => import('./content/applications/Brands/'))
);

const Banners = Loader(
  lazy(() => import('./content/applications/Banners/'))
);

const Contacts = Loader(
  lazy(() => import('./content/applications/Contacts/'))
);



//Institucional
const HowWork = Loader(
  lazy(()=> import('./pages/HowWork/HowWork'))
);

const Contact = Loader(
  lazy(()=> import('./pages/Contact/Contact'))
);


// Components
const Buttons = Loader(
  lazy(() => import('./content/pages/Components/Buttons'))
);

const Modals = Loader(
  lazy(() => import('./content/pages/Components/Modals'))
);

const Accordions = Loader(
  lazy(() => import('./content/pages/Components/Accordions'))
);

const Tabs = Loader(lazy(() => import('./content/pages/Components/Tabs')));

const Badges = Loader(
  lazy(() => import('./content/pages/Components/Badges'))
);

const Tooltips = Loader(
  lazy(() => import('./content/pages/Components/Tooltips'))
);

const Avatars = Loader(
  lazy(() => import('./content/pages/Components/Avatars'))
);

const Cards = Loader(lazy(() => import('./content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('./content/pages/Components/Forms')));


// Status
const Status404 = Loader(
  lazy(() => import('./content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('./content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('./content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('./content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <UserStorage>
                  <Home/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                </UserStorage>
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },

      {
        path: '/produto/:id',
        element: <UserStorage>
                  <SingleProduct />
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/como-funciona',
        element: <UserStorage>
                  <HowWork/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/cadastro-ead',
        element: <UserStorage>
                  <Ead/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/contato',
        element: <UserStorage>
                  <Contact/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/venda-liftpod-ft140-plataforma-low-level',
        element: <UserStorage>
                  <LpNest/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/alugue-online',
        element: <UserStorage>
                  <Solutions/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/demonstracao',
        element: <UserStorage>
                  <Demonstration/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/politica-de-privacidade',
        element: <UserStorage>
                  <Privacy/>
                  <ZohoSalesIQ/>
                  <WhatsappFloat/>
                 </UserStorage>
      },
      {
        path: '/login',
        element: 
              <UserStorage>
                <LoginForm/>
              </UserStorage>
      },
      {
        path: '/cadastro',
        element: 
        <UserStorage><LoginCreate/></UserStorage>
      },

      {
        path: '/recuperar-senha',
        element: <LoginForgotPassword />
      },

      {
        path: '/aluguel-concluido',
                
        element: 
            <UserStorage>
              <Thanks />
              <ZohoSalesIQ/>
            </UserStorage>
       
      },
      {
        path: '/redefinir-senha/*',
        element: <LoginRecoveryPassword/>
        
      },
      {
        path: 'checkout',
        element: <UserStorage>
                  <Checkout/>
                  <ZohoSalesIQ/>
                </UserStorage>
      },

      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },

  
  //ADMIN
  {
    path: 'dashboard',
    element:  <UserStorage>
                <SidebarLayout />
              </UserStorage>,
    children: [
      {
        path: '',
        element: 
          <ProtectedRoute>
            <UserStorage>
              <Navigate to="admin/geral" replace />
            </UserStorage>
          </ProtectedRoute>
      },
      {
        path: 'admin/geral',
        element:  
            <ProtectedRoute>
              <UserStorage>
                <Overview/>
              </UserStorage>
            </ProtectedRoute>
        
      },
      {
        path: 'admin/pedidos',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Orders/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'admin/devolucao',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Devolution/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'admin/clientes',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Clients/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'admin/cadastrar-categoria',
        element:  <ProtectedRoute>
                    <UserStorage>
                    <RegisterCategory />
                    </UserStorage>
                  </ProtectedRoute>
        
      },
      {
        path: 'admin/cadastrar-produto',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <RegisterProduct />
                    </UserStorage>
                  </ProtectedRoute>
      },

      {
        path: 'admin/orcamentos',
        element:  <ProtectedRoute>
                    <Budget />
                  </ProtectedRoute>
      },

      {
        path: 'admin/frete',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Ship/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'admin/cadastrar-marca',
        element:  <ProtectedRoute>
                    <Brands />
                  </ProtectedRoute>
      },
      {
        path: 'admin/banners',
        element:  <ProtectedRoute>
                    <Banners />
                  </ProtectedRoute>
      },
      {
        path: 'admin/contatos',
        element:  <ProtectedRoute>
                    <Contacts />
                  </ProtectedRoute>
      },
      {
        path: 'perfil',
        children: [
          {
            path: '',
            element: <Navigate to="admin/detalhes" replace />
          },
          {
            path: 'admin/detalhes',
            element:  <ProtectedRoute>
                        <UserStorage>
                          <UserProfile />
                        </UserStorage>
                      </ProtectedRoute>
          },
          {
            path: 'admin/configuracoes',
            element:  <ProtectedRoute>
                        <UserStorage>
                          <UserSettings />
                        </UserStorage>
                      </ProtectedRoute>
          },
        ]
      }
    ]
  },


  //CLIENT
  {
    path: 'dashboard',
    element:  <UserStorage>
                <SidebarLayout />
              </UserStorage>,
    children: [
      {
        path: '',
        element: 
          <ProtectedRoute>
            <UserStorage>
              <Navigate to="cliente/geral" replace />
            </UserStorage>
          </ProtectedRoute>
      },
      {
        path: 'cliente/meus-pedidos',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <OrdersClient/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'cliente/ead',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Ead/>
                    </UserStorage>
                  </ProtectedRoute>
      },
      {
        path: 'cliente/devolucao',
        element:  <ProtectedRoute>
                    <UserStorage>
                      <Devolution/>
                    </UserStorage>
                  </ProtectedRoute>
      },
  
      {
        path: 'perfil',
        children: [
          {
            path: '',
            element: <Navigate to="cliente/detalhes" replace />
          },
          {
            path: 'cliente/detalhes',
            element:  <ProtectedRoute>
                        <UserStorage>
                          <UserProfile />
                        </UserStorage>
                      </ProtectedRoute>
          },
          {
            path: 'cliente/configuracoes',
            element:  <ProtectedRoute>
                        <UserStorage>
                          <UserSettings />
                        </UserStorage>
                      </ProtectedRoute>
          },
        ]
      }
    ]
  },


  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
