import React, { useEffect } from 'react';
import styles from './Checkout.module.scss';
import { checkContext, CheckoutContext } from '../../context/CheckoutContext';
import { useContext } from 'react';
import { Input } from '../../components/Input/Input';
import { useFetch } from '../../hooks/useFetch';
import { Title } from '../../components/Title/Title';
import { Document0 } from '../Document/Document0';
import { useForm } from '../../hooks/useForm';
import { Loading } from '../../components/Loading/Loading';
import {
  CREATE_DOCUMENT,
  CREATE_DOCUMENT_SIGNER,
  JOIN_DOCUMENT_SIGNER
} from '../../api/Clicksign/ApiClicksign';
import { api } from 'src/api/api';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from 'src/context/UserContext';
import SearchCep from 'src/helpers/SearchCep';
import { WarningTrainingModal } from 'src/components/Modals/WarningTrainingModal/WarningTrainingModal';
import ZohoSalesIQ from 'src/components/ChatZoho/ChatZoho';
import http from 'src/http';
import { LoadingButton } from '@mui/lab';
import Training from '../Training/Training';

interface LocationProduct {
  name: string;
  id_produto: number;
}

export const Checkout = () => {
  const razaoSocial = useForm('');
  const fantasyName = useForm('');
  const cnpj = useForm('cnpj');
  const insc_estadual = useForm('insc_estadual');
  const email_company = useForm('email');
  const email_user = useForm('email');
  const tel_company = useForm('telefone');
  const tel_user = useForm('telefone');
  const passwordClient = useForm('password');

  const [nameUser, setNameUser] = React.useState('');
  const [cpfUser, setCpfUser] = React.useState('');
  const [dateBirthday, setDateBirthday] = React.useState('');
  const [signKey, setSignKey] = React.useState('');
  const [tokenAuth, setTokenAuth] = React.useState('');
  const [tokenRefresh, setTokenRefresh] = React.useState('');
  const [keyDocument, setKeyDocument] = React.useState('');
  const [keySigner, setKeySigner] = React.useState('');
  const [keyDocumentSign, setKeyDocumentSign] = React.useState('');

  const [acceptTerms, setAcceptTerms] = React.useState<Boolean>(false);

  //Billing address (endereço de cobrança)
  const [billingStreet, setBillingStreet] = React.useState('');
  const [billingNeighbourhood, setBillingNeighbourhood] = React.useState('');
  const [billingCity, setBillingCity] = React.useState('');
  const [billingState, setBillingState] = React.useState('');
  const [numberAddressBilling, setNumberAddressBilling] = React.useState('');
  const [billingCep, setBillingCep] = React.useState('');
  const [billingComplement, setBillingComplement] = React.useState('');

  //Billing address (endereço de cobrança de usuário logado)
  const [billingStreetUserLog, setBillingStreetUserLog] = React.useState('');
  const [billingNeighbourhoodUserLog, setBillingNeighbourhoodUserLog] =
    React.useState('');
  const [billingCityUserLog, setBillingCityUserLog] = React.useState('');
  const [billingStateUserLog, setBillingStateUserLog] = React.useState('');
  const [numberAddressBillingUserLog, setNumberAddressBillingUserLog] =
    React.useState('');
  const [billingCepUserLog, setBillingCepUserLog] = React.useState('');
  const [billingComplementUserLog, setBillingComplementUserLog] =
    React.useState('');

  //Shipping address (endereço de entrega)
  const [shippingStreet, setShippingStreet] = React.useState('');
  const [shippingNeighbourhood, setShippingNeighbourhood] = React.useState('');
  const [shippingCity, setShippingCity] = React.useState('');
  const [shippingState, setShippingState] = React.useState('');
  const [numberAddressShipping, setNumberAddressShipping] = React.useState('');
  const [shippingAddress, setShippingAddress] = React.useState('');
  const [shippingCep, setShippingCep] = React.useState('');
  const [shippingComplement, setShippingComplement] = React.useState('');

  const [nameResp, setNameResp] = React.useState('');
  const [errorData, setErrorData] = React.useState('');
  const [modal, setModal] = React.useState(false);
  const [alunos, setAlunos] = React.useState(false);
  const [optionTraining, setOptionTraining] = React.useState('');

  const [idZohoAccount, setIdZohoAccount] = React.useState<string>(null);
  const [detalhesNovoPedido, setDetalhesNovoPedido] = React.useState(null);

  const [produto, setProduto] = React.useState(null);
  const [idOrcamento, setIdOrcamento] = React.useState('');
  const { request, error, data, loading } = useFetch();

  const { totalDays, billing, price, newPrice, startDate, endDate, idProduct } =
    useContext(checkContext);

  const { usuario } = useContext(UserContext);

  const location = useLocation();
  const { name, id_produto } = location.state as LocationProduct;

  /* Busca CEP 01*/
  async function buscaCepBilling(cep: string) {
    const json = await SearchCep(cep);
    setBillingStreet(json.logradouro);
    setBillingCity(json.localidade);
    setBillingState(json.uf);
    setBillingNeighbourhood(json.bairro);
  }

  /* Busca CEP 02*/
  async function buscaCepShipping(cep: string) {
    const json = await SearchCep(cep);
    setShippingStreet(json.logradouro);
    setShippingNeighbourhood(json.bairro);
    setShippingCity(json.localidade);
    setShippingState(json.uf);
    setShippingAddress(
      json.logradouro + ', ' + json.bairro + ', ' + json.localidade
    );
  }

  function formataCPF(cpf: string) {
    cpf = cpf.replace(/[^\d]/g, '');
    setCpfUser(cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'));
  }

  async function carregar() {
    const { data } = await api.get(`/produtos/1`);
    setProduto(data);
  }

  function dataAtualFormatada(date: string) {
    var data = new Date(date),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + '/' + mesF + '/' + anoF;
  }

  //Cria modelo do documento
  const createModelDocument = async () => {
    if (usuario) {
      const { url, options } = CREATE_DOCUMENT({
        document: {
          path: '/modelos/contrato.docx',
          template: {
            data: {
              fantasy_name: fantasyName.value,
              address_pay: `Rua ${billingStreetUserLog}, ${billingNeighbourhoodUserLog}, ${billingCityUserLog}, ${billingStateUserLog}, N°${numberAddressBillingUserLog}`,
              address_billing: `Rua ${shippingStreet}, ${shippingNeighbourhood}, ${shippingCity}, ${shippingState} N°${numberAddressShipping}`,
              contact: email_company.value,
              business_email: email_company.value,
              total_days: totalDays,
              initial_date: dataAtualFormatada(startDate),
              final_date: dataAtualFormatada(endDate),
              cnpj: cnpj.value,
              billing: billing.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
              total:
                usuario?.id_perfil == 3
                  ? (newPrice + billing).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : (price + billing).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    }),
              machine_name: name,
              code_contract: Math.floor(Math.random() * 650),
              price_product: newPrice.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
              phone_number: tel_company.value
            }
          }
        }
      });
      const { key } = await request(url, options);
      setKeyDocument(key);
      //window.localStorage.setItem('model_document_key', key);
    } else if (usuario == null) {
      if (
        cnpj.validate() &&
        razaoSocial &&
        fantasyName &&
        email_company.value &&
        numberAddressShipping &&
        numberAddressBilling
      ) {
        const { url, options } = CREATE_DOCUMENT({
          document: {
            path: '/modelos/contrato.docx',
            template: {
              data: {
                fantasy_name: fantasyName.value,
                address_pay: `Rua ${billingStreet}, ${billingNeighbourhood}, ${billingCity}, ${billingState}, N°${numberAddressBilling}`,
                address_billing: `Rua ${shippingStreet}, ${shippingNeighbourhood}, ${shippingCity}, ${shippingState} N°${numberAddressShipping}`,
                contact: email_company.value,
                business_email: email_company.value,
                total_days: totalDays,
                initial_date: dataAtualFormatada(startDate),
                final_date: dataAtualFormatada(endDate),
                cnpj: cnpj.value,
                billing: billing.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                }),
                total:
                  usuario?.id_perfil == 3
                    ? (newPrice + billing).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })
                    : (price + billing).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      }),
                machine_name: name,
                code_contract: Math.floor(Math.random() * 650),
                price_product: newPrice.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                }),
                phone_number: tel_company.value
              }
            }
          }
        });
        const { key } = await request(url, options);
        setKeyDocument(key);
        window.localStorage.setItem('model_document_key', key);
        window.localStorage.setItem('name_user', fantasyName.value);
      } else {
        setErrorData('Preencha todos os campos obrigatórios.');
      }
    }
  };

  async function atualizarPedido(key?: string) {
    const tt = await api.put(`/pedidos/${detalhesNovoPedido?.id}`, {
      id_tokencontrato: key,
      id_orcamento: idOrcamento,
      descricao: '????',
      data_inicio: detalhesNovoPedido?.data_inicio,
      data_entrega: detalhesNovoPedido?.data_entrega,
      vr_total: detalhesNovoPedido?.vr_total,
      id_endereco: detalhesNovoPedido?.id_endereco,
      id_cupom_desconto: null,
      id_cliente: detalhesNovoPedido?.id_cliente,
      id_usuario: detalhesNovoPedido?.id_usuario,
      itens: [
        {
          id_produto: detalhesNovoPedido?.itens[0].id_produto,
          valor: detalhesNovoPedido?.itens[0].valor,
          vr_desconto: 0,
          quantidade: 1
        }
      ]
    });
    return tt;
  }

  async function atualizarPedidoUsuarioLogado(key?: string) {
    const tt = await api.put(`/pedidos/${detalhesNovoPedido?.id}`, {
      id_tokencontrato: key,
      id_orcamento: idOrcamento,
      descricao: '????',
      data_inicio: detalhesNovoPedido?.data_inicio,
      data_entrega: detalhesNovoPedido?.data_entrega,
      vr_total: detalhesNovoPedido?.vr_total,
      id_endereco: detalhesNovoPedido?.id_endereco,
      id_cupom_desconto: null,
      id_cliente: detalhesNovoPedido?.id_cliente,
      id_usuario: detalhesNovoPedido?.id_usuario,
      itens: [
        {
          id_produto: detalhesNovoPedido?.itens[0].id_produto,
          valor: detalhesNovoPedido?.itens[0].valor,
          vr_desconto: 0,
          quantidade: 1
        }
      ]
    });
    return tt;
  }

  //Cria documento e retorna sua chave
  const createDocumentKey = async (keySign: string) => {
    const fetchDocumentKey = fetch(
      'https://api2.nestrental.com.br/api/clicksign/create-document',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          list: {
            document_key: keyDocument,
            signer_key: keySign,
            sign_as: 'sign',
            refusable: true,
            message: `Prezado ${nameUser},\nPor favor assine o documento.\n\nQualquer dúvida estou à disposição.\n\nAtenciosamente,\nNest Rental.`
          }
        })
      }
    );
    const response = await fetchDocumentKey;
    const json = await response.json();
    const key = await json?.key_document_signer;
    sendNotificationDocument(key);
    if (key) {
      if (usuario) {
        await atualizarPedidoUsuarioLogado(keyDocument);
      } else {
        setTimeout(() => {
          atualizarPedido(keyDocument);
        }, 3000);
      }
      setKeyDocumentSign(key);
      window.localStorage.setItem('document_key_and_signer', key);
    } else {
      setErrorData('CPF inválido. Tente novamente!');
    }
  };
  //Cria documento e retorna sua chave
  const sendNotificationDocument = async (key: string) => {
    http.post('/clicksign/send-notification', {
      request_signature_key: key,
      message: `Prezado ${usuario ? usuario.razao_social : razaoSocial.value},
                    \nPor favor assine o documento.\n\nQualquer dúvida estou à disposição.\n\nAtenciosamente,\nNest Rental.`,
      url: ''
    });
  };

  //Cria signatário do documento
  const createSignerDocument = async () => {
    if (
      cpfUser &&
      email_user.value &&
      dateBirthday &&
      nameUser &&
      tel_user.value
    ) {
      if (!keyDocumentSign) {
        const { url, options } = CREATE_DOCUMENT_SIGNER({
          signer: {
            email: email_user.value,
            phone_number: tel_user.value,
            auths: ['email'],
            name: nameUser,
            documentation: cpfUser,
            birthday: dateBirthday,
            has_documentation: true,
            selfie_enabled: false,
            handwritten_enabled: false,
            official_document_enabled: false,
            liveness_enabled: false,
            facial_biometrics_enabled: false
          }
        });
        const { key } = await request(url, options);
        if (key) {
          setKeySigner(key);
          await createDocumentKey(key);
          return key;
        } else if (!key) {
          setErrorData('CPF inválido. Tente novamente!');
        }
      } else {
        setErrorData('Este contrato já foi gerado...');
      }
    } else {
      setErrorData('Preencha todos os campos obrigatórios.');
    }
  };

  //Carrega dados de usuário que já está logado.
  async function carregar_usuario_logado() {
    const id_usuario = usuario?.id;
    const { data } = await api.get(`/usuarios/${id_usuario}`);
    const {
      razao_social,
      inscricao_estadual,
      nome_fantasia,
      nome,
      email,
      documento
    } = data.entidade;

    setBillingCepUserLog(data?.entidade.enderecos[0].endereco.cep);
    setBillingStreetUserLog(data?.entidade.enderecos[0].endereco.rua);
    setNumberAddressBillingUserLog(data?.entidade.enderecos[0].endereco.numero);
    setBillingCityUserLog(data?.entidade.enderecos[0].endereco.cidade.nome);
    setBillingStateUserLog(
      data?.entidade.enderecos[0].endereco.cidade.estado.sigla
    );
    setBillingNeighbourhoodUserLog(data?.entidade.enderecos[0].endereco.bairro);
    cnpj.setValue(documento);
    razaoSocial.setValue(razao_social);
    email_company.setValue(email);
    fantasyName.setValue(nome_fantasia);
    insc_estadual.setValue(inscricao_estadual);
  }

  async function carregar_endereco() {
    const id_usuario = usuario?.id;
    const { data } = await api.get(`/enderecos`);
    const endereco_entrega = data.find((e) => e.tipo == 'E');
    setShippingCep(endereco_entrega.cep);
    setShippingNeighbourhood(endereco_entrega.bairro);
    setShippingStreet(endereco_entrega.rua);
    setNumberAddressShipping(endereco_entrega.numero);

    const endereco_cobranca = data.find((e) => e.tipo == 'C');

    setBillingCep(endereco_cobranca.cep);
    setBillingNeighbourhood(endereco_cobranca.bairro);
    setBillingStreet(endereco_cobranca.rua);
    setNumberAddressBilling(endereco_cobranca.numero);
  }

  async function criar_usuario() {
    const { data } = await api.post('/usuarios', {
      nome: fantasyName.value,
      documento: cnpj.value,
      tipo: 'J',
      razao_social: razaoSocial.value,
      email: email_company.value,
      nome_fantasia: fantasyName.value,
      inscricao_estadual: insc_estadual.value,
      login: email_company.value,
      password: passwordClient.value,
      id_perfil: 2
    });

    return data;
  }

  async function openModal(e) {
    e.preventDefault();
    if (
      cpfUser &&
      email_user.value &&
      dateBirthday &&
      nameUser &&
      tel_user.value
    ) {
      let key = await createSignerDocument();
      if (key) {
        setModal(true);
      }
    } else {
      setErrorData('Preencha todos os campos obrigatórios.');
    }
  }

  async function buscar_cidade(nome_cidade: string) {
    //Considerando apenas cidades de sao paulo.
    const nome = encodeURI(nome_cidade);
    const { data } = await api.get(`/cidades/${nome}`);
    return data?.id;
  }

  async function criar_endereco_cobranca(id_entidade) {
    const { data } = await api.post(`/enderecos/${id_entidade}`, {
      id_cidade: await buscar_cidade(billingCity),
      cep: billingCep,
      bairro: billingNeighbourhood,
      complemento: billingComplement ? billingComplement : 'Não possui',
      rua: billingStreet,
      numero: numberAddressBilling,
      contato: '',
      telefone: '',
      email: email_company.value,
      nome_obra: '',
      tipo: 'C' //E = Entrega C = Cobrança
    });

    return data;
  }

  async function criar_endereco_entrega(id_entidade) {
    const { data } = await api.post(`/enderecos/${id_entidade}`, {
      id_cidade: await buscar_cidade(shippingCity),
      cep: shippingCep,
      bairro: shippingNeighbourhood,
      complemento: shippingComplement ? shippingComplement : 'Não possui',
      rua: shippingStreet,
      numero: numberAddressShipping,
      contato: nameResp,
      telefone: tel_company.value,
      email: email_company.value,
      nome_obra: nameResp,
      tipo: 'E' //E = Entrega C = Cobrança
    });

    return data;
  }

  async function criar_pedido(
    id_endereco?: any,
    id_usuario?: any,
    id_entidade?: any
  ) {
    let opt = null;
    if (selectedCheckbox === 'checkbox1') {
      opt = 3;
    } else if (selectedCheckbox === 'checkbox2') {
      opt = 13;
    } else if (selectedCheckbox === 'checkbox3') {
      opt = 14;
    }

    const total = (newPrice || price) + billing;
    const { data } = await api.post(`/pedidos`, {
      descricao: '????',
      data_inicio: startDate,
      data_entrega: endDate,
      vr_total: total,
      id_endereco: id_endereco,
      id_cupom_desconto: null,
      id_cliente: id_entidade,
      id_usuario: id_usuario,
      id_tokencontrato: '',
      id_orcamento: '',
      status: 'aberto',
      id_opcao_pagamento: opt,
      itens: [
        {
          id_produto: idProduct,
          valor: total,
          vr_desconto: 0,
          quantidade: 1
        }
      ]
    });

    return data;
  }

  async function criar_pedido_usuario_logado(
    id_endereco?: any,
    id_usuario?: any,
    id_entidade?: any
  ) {
    const total = (newPrice || price) + billing;
    const id_produto = 1;
    const { data } = await api.post(`/pedidos`, {
      descricao: '????',
      data_inicio: startDate,
      data_entrega: endDate,
      vr_total: total,
      id_endereco: id_endereco,
      id_cupom_desconto: null,
      id_cliente: id_entidade,
      id_usuario: id_usuario,
      id_tokencontrato: '',
      id_orcamento: '',
      itens: [
        {
          id_produto: id_produto,
          valor: total,
          vr_desconto: 0,
          quantidade: 1
        }
      ]
    });

    return data;
  }

  //salva novo cliente no banco de dados e envia lead para zoho
  async function salvarPedidoUsuarioLogado(e?: any) {
    e?.preventDefault();
    if (
      cnpj.validate() &&
      razaoSocial.validate() &&
      fantasyName &&
      email_company.value &&
      numberAddressShipping &&
      numberAddressBilling &&
      insc_estadual.validate
    ) {
      try {
        let entidade_id, user_id;
        if (usuario == null) {
          const { entidade, user } = await criar_usuario();
          entidade_id = entidade.id;
          user_id = user.id;

          let id_zoho = await createAccountZoho(user_id);
          setIdZohoAccount(id_zoho);
        } else {
          entidade_id = usuario.id_entidade;
          user_id = usuario.id;
        }

        const { endereco } = await criar_endereco_cobranca(entidade_id);
        const { endereco: endereco_entrega } = await criar_endereco_entrega(
          entidade_id
        );
        const pedido = await criar_pedido(endereco, user_id, entidade_id);
        return pedido;
      } catch (err) {
        if (err?.response?.data?.errors[0].field == 'login') {
          setErrorData('Este email já está vinculado a uma conta. Faça login!');
        } else if (err?.response?.data?.errors[0].field == 'documento') {
          setErrorData('Este CNPJ já está vinculado a uma conta. Faça login!');
        }
      }
    }
  }

  //Envia ORÇAMENTO para o ZOHO CRM
  const sendQuote = async () => {
    const fetchQuote = fetch('https://api2.nestrental.com.br/api/zoho/create-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            Owner: {
              name: 'Tatiana Tavora Dias',
              id: '702098344',
              email: 'tdias@nestrental.com.br'
            },

            //settings
            $currency_symbol: 'BRL',
            Currency: 'BRL',
            Adjustment: 0,
            $editable: true,
            Tax: 0,

            //billing address
            Billing_Country: 'Brasil',
            Billing_Street: billingStreet,
            Billing_Code: billingCep,
            Billing_City: billingCity,
            Billing_State: billingState,

            //shipping address
            Shipping_Country: 'Brasil',
            Shipping_Code: shippingCep,
            Shipping_Street: shippingStreet,
            Shipping_State: shippingState,
            Shipping_City: shippingCity,

            Carrier: 'Nest Rental',

            $approval: {
              delegate: false,
              approve: false,
              reject: false,
              resubmit: false
            },

            Product_Details: [
              {
                product: {
                  Product_Code: null,
                  Currency: 'BRL',
                  name: 'sample',
                  id: '4288853000019876145'
                },

                quantity: 1,
                Discount: 0,
                total_after_discount: price + billing,
                net_total: 0,
                book: null,
                Tax: 0,
                list_price: price,
                unit_price: 0,
                quantity_in_stock: -1,
                total: price,
                id: '4288853000019876145',
                product_description: produto?.descricao,
                line_tax: []
              },
              {
                product: {
                  Product_Code: null,
                  Currency: 'BRL',
                  name: 'sample',
                  id: '4288853000000510001'
                },

                quantity: 1,
                Discount: 0,
                total_after_discount: billing,
                net_total: 0,
                book: null,
                Tax: 0,
                list_price: billing,
                unit_price: 0,
                quantity_in_stock: -1,
                total: billing,
                id: '4288853000000510001',
                product_description: '',
                line_tax: []
              }
            ],

            Discount: 0,
            Description: produto?.descricao,

            $review_process: {
              approve: false,
              reject: false,
              resubmit: false
            },

            $review: null,
            Valid_Till: null,

            Account_Name: {
              name: usuario?.razao_social,
              id: usuario?.id_zoho
            },

            Quote_Stage: 'Pendente',
            Terms_and_Conditions: null,
            Sub_Total: price + billing,
            Subject: 'Locação',
            $orchestration: false,
            Contact_Name: null,
            $in_merge: false,
            $line_tax: [],
            Tag: []
          }
        ]
      })
    });
    const response = await fetchQuote;
    const json = await response.json();
    setIdOrcamento(json.message[0].details.id);
    setErrorData('');
  };

  //Envia ORÇAMENTO para o ZOHO CRM
  const sendQuoteNewUser = async (idZoho?: string) => {
    const fetchQuote = fetch('https://api2.nestrental.com.br/api/zoho/create-quote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            Owner: {
              name: 'Tatiana Tavora Dias',
              id: '702098344',
              email: 'tdias@nestrental.com.br'
            },
            //settings
            $currency_symbol: 'BRL',
            Currency: 'BRL',
            Adjustment: 0,
            $editable: true,
            Tax: 0,
            //billing address
            Billing_Country: 'Brasil',
            Billing_Street: billingStreet,
            Billing_Code: billingCep,
            Billing_City: billingCity,
            Billing_State: billingState,
            //shipping address
            Shipping_Country: 'Brasil',
            Shipping_Code: shippingCep,
            Shipping_Street: shippingStreet,
            Shipping_State: shippingState,
            Shipping_City: shippingCity,
            Carrier: 'Nest Rental',
            $approval: {
              delegate: false,
              approve: false,
              reject: false,
              resubmit: false
            },

            Product_Details: [
              {
                product: {
                  Product_Code: null,
                  Currency: 'BRL',
                  name: 'sample',
                  id: '4288853000019876145'
                },
                quantity: 1,
                Discount: 0,
                total_after_discount: price + billing,
                net_total: 0,
                book: null,
                Tax: 0,
                list_price: price,
                unit_price: 0,
                quantity_in_stock: -1,
                total: price,
                id: '4288853000019876145',
                product_description: produto?.descricao,
                line_tax: []
              },
              {
                product: {
                  Product_Code: null,
                  Currency: 'BRL',
                  name: 'sample',
                  id: '4288853000000510001'
                },
                quantity: 1,
                Discount: 0,
                total_after_discount: billing,
                net_total: 0,
                book: null,
                Tax: 0,
                list_price: billing,
                unit_price: 0,
                quantity_in_stock: -1,
                total: billing,
                id: '4288853000000510001',
                product_description: '',
                line_tax: []
              }
            ],
            Discount: 0,
            Description: produto?.descricao,
            $review_process: {
              approve: false,
              reject: false,
              resubmit: false
            },
            $review: null,
            Valid_Till: null,

            Account_Name: {
              name: usuario ? usuario?.razao_social : razaoSocial.value,
              id: usuario ? usuario?.id_zoho : idZoho
            },

            Quote_Stage: 'Pendente',
            Terms_and_Conditions: null,
            Sub_Total: price + billing,
            Subject: 'Locação',
            $orchestration: false,
            Contact_Name: null,
            $in_merge: false,
            $line_tax: [],
            Tag: []
          }
        ]
      })
    });
    const response = await fetchQuote;
    const json = await response.json();
    setIdOrcamento(json.message[0].details.id);
    //createModelDocument();
    setErrorData('');
  };

  async function updateUserIdZoho(idZoho: string, idUser: number) {
    const fetchUpdateUser = fetch(
      `https://api2.nestrental.com.br/api/usuarios/${idUser}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id_zoho: idZoho,
          nome: '???',
          documento: cnpj.value,
          tipo: 'J',
          razao_social: razaoSocial.value,
          email: email_company.value,
          nome_fantasia: fantasyName.value,
          inscricao_estadual: insc_estadual.value,
          login: email_company.value,
          password: passwordClient.value,
          id_perfil: 2
        })
      }
    );

    const response = await fetchUpdateUser;
    const json = await response.json();
  }

  const createAccountZoho = async (idUser?: string) => {
    setErrorData('');
    const fetchLead = fetch('https://api2.nestrental.com.br/api/zoho/create-account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: [
          {
            Owner: {
              //Name, ID, and email of the owner of the Account
              name: 'Tatiana Tavora Dias',
              id: '702098344',
              email: 'tdias@nestrental.com.br'
            },

            Inscri_o_Estadual: insc_estadual.value,
            CNPJ: cnpj.value,
            Raz_o_Social: razaoSocial.value,
            Phone: tel_user.value,
            Origem_prospect: 'E-commerce',
            $currency_symbol: 'BRL', //The currency in which the revenue is generated
            Account_Type: 'Lead', //Represents the type of account
            Industry: 'Instaladoras', //The name of the industry of the account
            Account_Site: 'https://nest-rental-frontend.herokuapp.com', //The name of the account's location, for example, Headquarters or London.
            $process_flow: false, //Represents if the record is a blueprint data
            Exchange_Rate: 3, //Represents of the currency in which the revenue is generated
            Currency: 'BRL', //The symbol of the currency in which the revenue is generated
            Billing_Country: 'Brasil', //The billing address of the account to send the quotes, invoices, and other agreements
            $approval: {
              //Represents if the current user can approve, delegate, reject, or resubmit the operations performed on this record
              delegate: false,
              approve: false,
              reject: false,
              resubmit: false
            },

            Billing_Street: billingStreet, //Represents the address details of the account
            $editable: true, //Represents if the user can edit records in the Accounts module
            Billing_Code: billingCep, //Represents the address details of the account
            Shipping_City: shippingCity, //Represents the address details of the account
            Shipping_Country: 'Brasil', //Represents the address details of the account
            Shipping_Code: shippingCep, //Represents the address details of the account
            Billing_City: billingCity, //Represents the address details of the account

            Created_By: {
              //Name and ID of the user who created the record. This is a system-generated field. You cannot modify it.
              name: 'Tatiana Tavora Dias',
              id: '702098344',
              email: 'tdias@nestrental.com.br'
            },

            Shipping_Street: shippingStreet, //Represents the address details of the account
            Ownership: 'Private', //Represents the ownership type of the account
            Rating: 'Active', //Represents the rating of the account
            Shipping_State: 'SP', //Represents the address details of the account

            $review_process: {
              //Represents the review process details of the account
              approve: false,
              reject: false,
              resubmit: false
            },

            Website: 'https://nest-rental-frontend.herokuapp.com', //Represents the website of the account
            Account_Name: fantasyName.value, //Represents the name of the account

            $in_merge: false,
            Contact_Details: [
              {
                Email: email_company.value
              }
            ], //Represents the details of contacts associated with the account
            Billing_State: billingState, //Represents the address details of the account
            Tag: [] //List of tags associated with the record
          }
        ]
      })
    });

    const response = await fetchLead;
    const json = await response.json();

    if (json.message[0].code === 'SUCCESS') {
      //id zoho vinculado a uma nova conta
      const idZoho = await json.message[0].details.id;
      return idZoho;
    } else if (
      json.message.data[0].code === 'DUPLICATE_DATA' &&
      json.message.data[0].details.api_name === 'CNPJ'
    ) {
      setErrorData(
        'Este CNPJ já está associado a uma conta! Por favor, faça login.'
      );
    }
  };

  const [dataNewUser, setDataNewUser] = React.useState([]);

  //if not client, save client in database, create account in zoho crm, create document and return id zoho.
  //if client, create a new quote and send to zoho crm
  async function salvar(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (acceptTerms) {
      let entidade_id, user_id, endereco_id;
      if (usuario) {
        entidade_id = usuario.id_entidade;
        user_id = usuario.id;
        const postTerms = await api.post('/termos', {
          id_entidade: usuario.id_entidade
        });
        await sendQuote();
        await createModelDocument();
        const { endereco_entrega } = await criar_endereco_entrega(entidade_id);
        const pedido = await criar_pedido(
          endereco_entrega,
          user_id,
          entidade_id
        );
        setDetalhesNovoPedido(pedido);
      } else if (usuario == null) {
        if (
          cnpj.validate() &&
          razaoSocial.validate() &&
          fantasyName &&
          email_company.value &&
          numberAddressShipping &&
          numberAddressBilling &&
          razaoSocial.validate() &&
          acceptTerms
        ) {
          try {
            const { entidade, user } = await criar_usuario();
            setDataNewUser([entidade, user]);
            entidade_id = entidade.id;
            user_id = user.id;
            let id_zoho = await createAccountZoho(user_id);
            setIdZohoAccount(id_zoho);
            await updateUserIdZoho(id_zoho, user_id);
            await sendQuoteNewUser(id_zoho);
            await createModelDocument();
            const { endereco } = await criar_endereco_cobranca(entidade_id);
            const { endereco_entrega } = await criar_endereco_entrega(
              entidade_id
            );
            const pedido = await criar_pedido(endereco, user_id, entidade_id);
            setDetalhesNovoPedido(pedido);
          } catch (err) {
            if (err?.response?.data?.errors[0].field == 'email') {
              setErrorData(
                'Este email já está vinculado a uma conta. Faça login!'
              );
            } else if (err?.response?.data?.errors[0].field == 'documento') {
              setErrorData(
                'Este CNPJ já está vinculado a uma conta. Faça login!'
              );
            }
          }
        }
      }
    } else {
      setErrorData('Por favor, aceite os termos de política e privacidade.');
    }
  }

  React.useEffect(() => {
    setOptionTraining(window.localStorage.getItem('option_training'));
  }, [window.localStorage.getItem('option_training')]);

  React.useEffect(() => {
    carregar();
  }, []);

  React.useEffect(() => {
    formataCPF(cpfUser);
  }, [cpfUser]);

  React.useEffect(() => {
    buscaCepBilling(billingCep);
  }, [billingCep]);

  React.useEffect(() => {
    buscaCepShipping(shippingCep);
  }, [shippingCep]);

  useEffect(() => {
    carregar_usuario_logado();
  }, []);

  const [selectedCheckbox, setSelectedCheckbox] = React.useState(null);

  const handleCheckboxChange = (checkboxId) => {
    setSelectedCheckbox(checkboxId);
  };

  return (
    <>
      {loading && <Loading />}
      <Header />

      <section
        style={{
          backgroundColor: '#125082',
          width: '100%',
          padding: '1rem 5rem',
          height: '150px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <h1
          style={{
            color: '#fff',
            textTransform: 'uppercase'
          }}
        >
          Pedido
        </h1>
      </section>

      {!keyDocument && (
        <section className={styles.divCheckout}>
          <form
            className={styles.formCheckout}
            style={{ marginTop: '-1.6rem' }}
          >
            <div className={styles.formCheckout__div}>
              <div
                style={{
                  display: 'block',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Title level={3}>Empresa</Title>

                {usuario ? (
                  <></>
                ) : (
                  <p
                    style={{
                      color: 'rgba(18, 80,130)',
                      fontWeight: '600',
                      textAlign: 'right',
                      marginTop: '-2.5rem'
                    }}
                  >
                    Já possui cadastro?
                    <Link
                      to="/login?redirect=/checkout"
                      style={{
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        color: 'rgba(18, 80,130)',
                        fontSize: '.875rem'
                      }}
                    >
                      {' '}
                      Faça Login
                    </Link>
                  </p>
                )}

                <Input
                  type="text"
                  label="Razão social"
                  name="razao_social"
                  id="razao_social"
                  placeholder="Digite sua razão social"
                  value={usuario ? usuario?.razao_social : ''}
                  {...razaoSocial}
                  disabled={usuario ? true : false}
                  required={true}
                />

                <div className={styles.formCheckout__div__inputs}>
                  <Input
                    type="text"
                    label="Nome fantasia*"
                    name="fantasy_name"
                    id="fantasy_name"
                    placeholder="Digite seu nome fantasia"
                    {...fantasyName}
                    disabled={usuario ? true : false}
                    required={true}
                  />

                  <Input
                    type="text"
                    label="CNPJ*"
                    name="cnpj"
                    id="cnpj"
                    placeholder="xx.xxx.xxx/xxxx-xx"
                    {...cnpj}
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>

                <div
                  className={styles.formCheckout__div__inputs}
                  style={{ marginTop: '-1.3rem' }}
                >
                  <div style={{ width: '100%' }}>
                    <Input
                      type="text"
                      label="Inscrição Estadual*"
                      name="insc_estadual"
                      id="insc_estadual"
                      placeholder="xx.xxx.xxx-x"
                      {...insc_estadual}
                      disabled={usuario ? true : false}
                      required={true}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Input
                      type="email"
                      label="Email*"
                      name="email_company"
                      id="email_company"
                      placeholder="emailexample@com.br"
                      {...email_company}
                      disabled={usuario ? true : false}
                      required={true}
                    />
                  </div>
                </div>
                <div style={{ marginTop: '-1rem' }}>
                  {usuario ? (
                    <> </>
                  ) : (
                    <Input
                      type="password"
                      label="Senha*"
                      name="new_pass_client"
                      id="new_pass_client"
                      placeholder="Digite sua senha"
                      {...passwordClient}
                      disabled={usuario ? true : false}
                      required={true}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* DETALHES DO FATURAMENTO*/}
            <div className={styles.formCheckout__div}>
              <Title level={3}>Detalhes do faturamento</Title>
              <div
                style={{
                  display: 'block',
                  paddingBottom: '.875rem'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gridGap: '1rem'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row'
                    }}
                  >
                    <input
                      type="checkbox"
                      id="a-vista"
                      checked={selectedCheckbox === 'checkbox1'}
                      onChange={() => handleCheckboxChange('checkbox1')}
                    />
                    <span>À vista</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <input
                      type="checkbox"
                      id="boleto-15"
                      checked={selectedCheckbox === 'checkbox2'}
                      onChange={() => handleCheckboxChange('checkbox2')}
                    />
                    <span>Boleto - 15 dias</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <input
                      type="checkbox"
                      id="boleto-28"
                      checked={selectedCheckbox === 'checkbox3'}
                      onChange={() => handleCheckboxChange('checkbox3')}
                    />
                    <span>Boleto - 28 dias</span>
                  </div>
                </div>
              </div>

              <div
                className={styles.formCheckout__div__inputs}
                style={{ marginTop: '-1rem' }}
              >
                <div style={{ width: '100%' }}>
                  <label style={{ display: 'block' }}>CEP*</label>
                  <input
                    type="number"
                    id=""
                    name=""
                    pattern="[0-9]+"
                    placeholder="Digite seu CEP"
                    onChange={(e) => setBillingCep(e.target.value)}
                    value={usuario ? billingCepUserLog : billingCep}
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <label>Rua/Av*</label>
                  <input
                    type="text"
                    id="street_faturamento"
                    name="street_faturamento"
                    placeholder="Digite sua rua ou avenida"
                    onChange={(e) => setBillingStreet(e.target.value)}
                    value={usuario ? billingStreetUserLog : billingStreet}
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <label>Número*</label>
                  <input
                    type="number"
                    id="number_faturamento"
                    placeholder="Digite o número"
                    name="number_faturamento"
                    onChange={(e) => setNumberAddressBilling(e.target.value)}
                    value={
                      usuario
                        ? numberAddressBillingUserLog
                        : numberAddressBilling
                    }
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>
              </div>

              <div
                className={styles.formCheckout__div__inputs}
                style={{ marginTop: '-1rem' }}
              >
                <div>
                  <label>Cidade*</label>
                  <input
                    type="text"
                    id="country_faturamento"
                    placeholder="Digite sua cidade"
                    name="country_faturamento"
                    onChange={(e) => setBillingCity(e.target.value)}
                    value={usuario ? billingCityUserLog : billingCity}
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>

                <div>
                  <label>UF*</label>
                  <input
                    type="text"
                    id="uf_faturamento"
                    placeholder="Digite seu estado"
                    name="uf_faturamento"
                    onChange={(e) => setBillingState(e.target.value)}
                    value={usuario ? billingStateUserLog : billingState}
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>

                <div>
                  <label>Bairro*</label>
                  <input
                    type="text"
                    id="bairro_faturamento"
                    placeholder="Digite seu bairro"
                    name="bairro_faturamento"
                    onChange={(e) => setBillingNeighbourhood(e.target.value)}
                    value={
                      usuario
                        ? billingNeighbourhoodUserLog
                        : billingNeighbourhood
                    }
                    disabled={usuario ? true : false}
                    required={true}
                  />
                </div>
                <div>
                  <label>Complemento</label>
                  <input
                    type="text"
                    id=""
                    name=""
                    placeholder="Digite um complemento"
                    disabled={usuario ? true : false}
                  />
                </div>
              </div>
            </div>

            {/*Detalhes de entrega*/}

            <div className={styles.formCheckout__div}>
              <Title level={3}>Detalhes de entrega</Title>
              <div
                className={styles.formCheckout__div__inputs}
                style={{ marginTop: '-1.5rem' }}
              >
                <div style={{ width: '100%' }}>
                  <label>Nome(responsável por receber):*</label>
                  <input
                    type="text"
                    id="name_resp"
                    name="name_resp"
                    placeholder="Digite o nome do responsável"
                    value={nameResp}
                    required={true}
                    onChange={(e) => setNameResp(e.target.value)}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <Input
                    type="text"
                    label="Telefone*"
                    name="tel_company"
                    id="tel_company"
                    placeholder="(00) 000000000"
                    {...tel_company}
                    disabled={false}
                    required={true}
                  />
                </div>
              </div>
              <div
                className={styles.formCheckout__div__inputs}
                style={{ marginTop: '-1.7rem' }}
              >
                <div style={{ width: '100%' }}>
                  <label>CEP*</label>
                  <input
                    type="number"
                    id="cep_e"
                    name="cep_e"
                    pattern="[0-9]+"
                    placeholder="Digite seu CEP"
                    onChange={(e) => setShippingCep(e.target.value)}
                    value={shippingCep}
                    required={true}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <label>Rua/Av*</label>
                  <input
                    type="text"
                    placeholder="Digite sua rua ou avenida"
                    id="street_entrega"
                    name="street_entrega"
                    onChange={(e) => setShippingStreet(e.target.value)}
                    value={shippingAddress}
                    required={true}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <label>Número*</label>
                  <input
                    type="number"
                    id="number_entrega"
                    name="number_entrega"
                    placeholder="Digite o número"
                    onChange={(e) => setNumberAddressShipping(e.target.value)}
                    value={numberAddressShipping}
                    required={true}
                  />
                </div>
              </div>

              <div
                className={styles.formCheckout__div__inputs}
                style={{ marginTop: '-1rem' }}
              >
                <div>
                  <label>Cidade*</label>
                  <input
                    type="text"
                    id="country_entrega"
                    placeholder="Digite sua cidade"
                    name="country_entrega"
                    onChange={(e) => setShippingCity(e.target.value)}
                    value={shippingCity}
                    required={true}
                  />
                </div>

                <div>
                  <label>UF*</label>
                  <input
                    type="text"
                    id=""
                    name=""
                    placeholder="Digite seu estado"
                    onChange={(e) => setShippingState(e.target.value)}
                    value={shippingState}
                    required={true}
                  />
                </div>
                <div>
                  <label>Bairro*</label>
                  <input
                    type="text"
                    id="bairro_entrega"
                    name="bairro_entrege"
                    placeholder="Digite seu bairro"
                    onChange={(e) => setShippingNeighbourhood(e.target.value)}
                    value={shippingNeighbourhood}
                    required={true}
                  />
                </div>
                <div>
                  <label>Complemento</label>
                  <input
                    type="text"
                    id=""
                    placeholder="Digite um complemento"
                    name=""
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gridGap: '1rem',
                padding: '1rem 0'
              }}
            >
              <input
                type="checkbox"
                name="terms"
                id="terms"
                className="terms"
                onChange={(e) => setAcceptTerms(!acceptTerms)}
              />
              <p style={{ fontSize: '.7rem' }}>
                Declaro que li e concordo com o Termo de Política de Privacidade
                e Cookies da Nest e dou consentimento para receber e-mails com
                informações sobre produtos e serviços e contato comercial.
              </p>
            </div>
          </form>

          {/***********************************************************/}
          <div className={styles.formCheckout__div2}>
            <Title>Resumo do pedido</Title>
            <form>
              <div className={styles.formCheckout__date}>
                <div>
                  <label htmlFor="start">início</label>
                  <input type="date" name="start" value={startDate} disabled />
                </div>
                <div>
                  <label htmlFor="end">Devolução</label>
                  <input type="date" name="end" value={endDate} disabled />
                </div>
              </div>

              <div style={{ padding: '0 0 0 0' }}>
                <p>
                  <b>Quantidade de dias: </b>
                  {totalDays}
                </p>
                <div>
                  <p style={{ marginTop: '-.8rem', paddingTop: '.5rem' }}>
                    {' '}
                    <b>Frete:</b>{' '}
                    {billing.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </p>
                  <p style={{ marginTop: '-.8rem', paddingTop: '.5rem' }}>
                    {' '}
                    <b>Aluguel:</b>{' '}
                    {usuario?.id_perfil === 3
                      ? newPrice.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : price.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                    <span>
                      {usuario?.id_perfil != 3 && price != 0 && (
                        <span style={{ fontSize: '.8rem', color: 'green' }}>
                          {' '}
                          (5% de desconto)
                        </span>
                      )}
                    </span>
                  </p>
                  <p style={{ marginTop: '-.8rem', paddingTop: '.5rem' }}>
                    <b>TOTAL: </b>
                    {billing != 0 && usuario?.id_perfil != 3
                      ? (price + billing).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : (newPrice + billing).toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                  </p>
                </div>
              </div>
              <LoadingButton
                type="submit"
                color="success"
                fullWidth={true}
                loading={loading ? true : false}
                variant="contained"
                onClick={(e) => salvar(e)}
              >
                Finalizar aluguel
              </LoadingButton>

              {errorData && (
                <p
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    paddingTop: '.5rem',
                    fontSize: '.7rem'
                  }}
                >
                  {errorData}
                </p>
              )}
            </form>
          </div>
        </section>
      )}

      {keyDocument && !modal && !alunos && (
        <section style={{ paddingRight: '5rem', marginTop: '2rem' }}>
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            <form style={{ marginLeft: '5rem' }}>
              <div
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  background: '#f1f1f1',
                  padding: '1rem 1rem',
                  borderRadius: '8px'
                }}
              >
                <Title level={3}>Dados do representante legal</Title>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '1rem  0',
                    gap: '1rem'
                  }}
                >
                  <div>
                    <label style={{ display: 'block' }}>Nome completo*</label>
                    <input
                      type="text"
                      id="name_user"
                      name="name_user"
                      onChange={(e) => setNameUser(e.target.value)}
                      placeholder="Digite seu nome"
                      style={{
                        width: '100%',
                        border: '1px solid #ccc',
                        padding: '.5rem .5rem',
                        background: 'transparent',
                        borderRadius: '8px'
                      }}
                      disabled={keyDocumentSign ? true : false}
                    />
                  </div>

                  <div>
                    <label style={{ display: 'block' }}>CPF*</label>
                    <input
                      type="text"
                      id="cpf_user"
                      name="cpf_user"
                      placeholder="000.000.000-00"
                      onChange={(e) => setCpfUser(e.target.value)}
                      value={cpfUser}
                      style={{
                        width: '100%',
                        border: '1px solid #ccc',
                        padding: '.5rem .5rem',
                        background: 'transparent',
                        borderRadius: '8px'
                      }}
                      disabled={keyDocumentSign ? true : false}
                    />
                  </div>

                  <div>
                    <label style={{ display: 'block' }}>
                      Data de nascimento*
                    </label>
                    <input
                      type="date"
                      id="date_birthday"
                      name="date_birthday"
                      onChange={(e) => setDateBirthday(e.target.value)}
                      style={{
                        width: '100%',
                        border: '1px solid #ccc',
                        padding: '.5rem .5rem',
                        background: 'transparent',
                        borderRadius: '8px'
                      }}
                      disabled={keyDocumentSign ? true : false}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '1rem 0',
                    gap: '1rem'
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Input
                      type="email"
                      label="Email*"
                      name="email_user"
                      id="email_user"
                      placeholder="emailexample@com.br"
                      {...email_user}
                      disabled={keyDocumentSign ? true : false}
                      required={true}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <Input
                      type="text"
                      label="Telefone*"
                      name="tel_user"
                      id="tel_user"
                      placeholder="(00) 000000000"
                      {...tel_user}
                      disabled={keyDocumentSign ? true : false}
                      required={true}
                    />
                  </div>
                </div>
                {errorData && (
                  <p
                    style={{
                      color: 'red',
                      textAlign: 'left',
                      paddingTop: '.5rem',
                      fontSize: '.7rem'
                    }}
                  >
                    {errorData}
                  </p>
                )}
              </div>

              <p style={{ color: 'red', fontSize: '.8rem' }}>
                *Preencha os dados cadastrais completos referentes ao seu
                documento.
              </p>
            </form>

            <div
              style={{
                backgroundColor: '#f1f1f1',
                width: 'auto',
                borderRadius: '1rem',
                padding: '2rem 2rem 2rem 2rem',
                height: '40%'
              }}
            >
              <form>
                <div
                  style={{
                    display: 'flex',
                    gridGap: '2rem',
                    width: '100%',
                    color: 'rgb(18, 80, 130)'
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <label
                      htmlFor="start"
                      style={{ display: 'block', fontWeight: '600' }}
                    >
                      início
                    </label>
                    <input
                      type="date"
                      name="start"
                      value={startDate}
                      disabled
                      style={{
                        width: '100%',
                        border: 'none',
                        padding: '.5rem .5rem',
                        background: '#c2c2c2',
                        borderRadius: '8px'
                      }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="end"
                      style={{ display: 'block', fontWeight: '600' }}
                    >
                      Devolução
                    </label>
                    <input
                      type="date"
                      name="end"
                      value={endDate}
                      disabled
                      style={{
                        width: '100%',
                        border: 'none',
                        padding: '.5rem .5rem',
                        background: '#c2c2c2',
                        borderRadius: '8px'
                      }}
                    />
                  </div>
                </div>

                <div>
                  <p>Quantidade de dias: {totalDays}</p>
                  <div>
                    <p style={{ paddingTop: '.5rem' }}>
                      Frete:{' '}
                      {billing.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL'
                      })}
                    </p>
                    <p style={{ paddingTop: '.5rem' }}>
                      Aluguel:{' '}
                      {usuario?.id_perfil === 3
                        ? newPrice.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })
                        : price.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                      <span>
                        {price != 0 && usuario?.id_perfil != 3 ? (
                          <span style={{ fontSize: '.8rem', color: 'green' }}>
                            {' '}
                            (5% de desconto)
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    </p>
                    <p style={{ paddingTop: '.5rem' }}>
                      <b>TOTAL: </b>
                      {billing != 0 && usuario?.id_perfil != 3
                        ? (price + billing).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })
                        : (newPrice + billing).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          })}
                    </p>
                  </div>
                </div>
                <LoadingButton
                  type="submit"
                  color="success"
                  fullWidth={true}
                  loading={loading ? true : false}
                  variant="contained"
                  onClick={(e) => openModal(e)}
                  disabled={keyDocumentSign ? true : false}
                >
                  Continuar
                </LoadingButton>
              </form>
            </div>
          </section>
        </section>
      )}

      {modal && (
        <Training
          modal={modal}
          setModal={setModal}
          detalhesPedido={{
            startDate,
            endDate,
            totalDays,
            billing,
            newPrice,
            price,
            idProduct,
            dataNewUser
          }}
          alunos={alunos}
          setAlunos={setAlunos}
          idPedido={detalhesNovoPedido?.id}
        />
      )}

      {keyDocumentSign && modal == false && alunos && (
        <>
          <div style={{ padding: '1rem 5rem' }}>
            <Document0 token={keyDocumentSign} setToken={setKeyDocumentSign} />
          </div>
        </>
      )}
      <Footer />
    </>
  );
};
