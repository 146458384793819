import styles from './Footer.module.scss';
import {
  FaWhatsapp,
  FaYoutube,
  FaFacebook,
  FaLinkedin,
  FaInstagram
} from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { FiPhoneCall } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/images/footer/logo-white.png';
import React from 'react';
import http from 'src/http';
import PDF from '../../public/condicoes-gerais.pdf';

const Footer = () => {
  const [emailLink, setEmailLink] = React.useState('');
  const [phoneLink, setPhoneLink] = React.useState('');
  const [whatsappLink, setWhatsappLink] = React.useState('');
  const [facebookLink, setFacebookLink] = React.useState('');
  const [linkedinLink, setLinkedinLink] = React.useState('');
  const [instagramLink, setInstagramLink] = React.useState('');

  //whatsapp
  async function getWhatsapp() {
    const fetchWhatsapp = fetch(`https://api2.nestrental.com.br/api/redes-sociais/6`);
    const dados = await fetchWhatsapp;
    const dataJson = await dados.json();
    console.log({ fetchWhatsapp, dados, dataJson });
    setWhatsappLink(dataJson.url);
  }

  //email
  async function getEmail() {
    const fetchEmail = fetch(`https://api2.nestrental.com.br/api/redes-sociais/5`);
    const dados = await fetchEmail;
    const dataJson = await dados.json();
    setEmailLink(dataJson.url);
  }

  //phone
  async function getPhone() {
    const fetchPhone = fetch(`https://api2.nestrental.com.br/api/redes-sociais/7`);
    const dados = await fetchPhone;
    const dataJson = await dados.json();
    setPhoneLink(dataJson.url);
  }

  //facebook
  async function getFacebook() {
    const fetchFacebook = fetch(`https://api2.nestrental.com.br/api/redes-sociais/1`);
    const dados = await fetchFacebook;
    const dataJson = await dados.json();
    setFacebookLink(dataJson.url);
  }

  //instagram
  async function getInstagram() {
    const fetchInstagram = fetch(`https://api2.nestrental.com.br/api/redes-sociais/4`);
    const dados = await fetchInstagram;
    const dataJson = await dados.json();
    setInstagramLink(dataJson.url);
  }

  //linkedin
  async function getLinkedin() {
    const fetchLinkedin = fetch(`https://api2.nestrental.com.br/api/redes-sociais/3`);
    const dados = await fetchLinkedin;
    const dataJson = await dados.json();
    setLinkedinLink(dataJson.url);
  }

  let anchor = null;

  React.useEffect(() => {
    getWhatsapp();
    getEmail();
    getPhone();
    getFacebook();
    getLinkedin();
    getInstagram();
  }, []);

  return (
    <>
      <section className={styles.footer}>
        <div className={styles.footer__logo}>
          <img src={LogoWhite} />
          <ul className={styles.footer__logo__text}>
            <li>
              <p>Substitua escadas e andaimes com segurança.</p>
            </li>
          </ul>
        </div>

        <div className={styles.footer__company}>
          <h4>Empresa</h4>
          <ul className={styles.footer__company__links}>
            <Link to="/alugue-online">Alugue Online</Link>
            <Link to="/demonstracao">Demonstração</Link>
            <a
              href={PDF}
              download="condicoes-gerais.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Condições gerais de contrato
            </a>
            <Link to="/politica-de-privacidade">Política de privacidade</Link>
          </ul>
        </div>

        <div className={styles.footer__contact}>
          <h4>Contato</h4>
          <ul className={styles.footer__contact__links}>
            <a href={whatsappLink}>
              <FaWhatsapp color="#fff" />
              <label>(11) 98844-6299</label>
            </a>
            <a href={phoneLink}>
              <FiPhoneCall color="#fff" />
              <label>0800 555 1015</label>
            </a>
            <a href={emailLink}>
              <MdOutlineEmail color="#fff" />
              <label>contato@nestrental.com.br</label>
            </a>
          </ul>
        </div>

        <div className={styles.footer__attendance}>
          <h4>Atendimento</h4>
          <p>Av. Roque Petroni Júnior, 1226 - São Paulo - SP, 04707-000</p>
          <p>Segunda à Sexta Feira das 8:00 às 17:30</p>
          <ul className={styles.footer__attendance__links}>
            <a href={facebookLink}>
              <FaFacebook size={23} />
            </a>
            <a href={instagramLink}>
              <FaInstagram size={23} />
            </a>
            <a href={linkedinLink}>
              <FaLinkedin size={23} />
            </a>
            <a href="https://www.youtube.com/channel/UC33dtBc5nbFWiuw8QVWt2pQ">
              <FaYoutube size={23} />
            </a>
          </ul>
        </div>
      </section>
      <section className={styles.footer_down}>
        <p>{''}</p>
      </section>
    </>
  );
};

export default Footer;
