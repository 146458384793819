import { Carousel } from 'react-responsive-carousel';
import styles from '../Carousel.module.scss';
import React from 'react';
import { useMedia } from 'src/hooks/useMedia';
import http from 'src/http';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const CarouselHome = () => {
  const mobile = useMedia('(max-width: 768px)');

  const [bannerDesktop, setBannerDesktop] = React.useState([]);
  const [bannerMobile, setBannerMobile] = React.useState([]);

  async function getBannersDesktop() {
    http.get('/arquivos').then((response) => {
      setBannerDesktop([
        response.data[2].anexo,
        response.data[3].anexo,
        response.data[6].anexo
      ]);
    });
  }

  async function getBannersMobile() {
    http.get('/arquivos').then((response) => {
      setBannerMobile([
        response.data[0].anexo,
        response.data[1].anexo,
        response.data[5].anexo
      ]);
    });
  }

  React.useEffect(() => {
    getBannersMobile();
    getBannersDesktop();
  }, []);

  return (
    <>
      {!mobile && bannerDesktop.length > 0 ? (
        <div style={{ margin: '0 auto', width: '100%' }}>
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            interval={8000}
          >
            {bannerDesktop.map((item, index) => {
              return (
                <div key={index}>
                  <img
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      margin: '0 auto'
                    }}
                    src={`https://api2.nestrental.com.br${item}`}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : (
        mobile &&
        bannerMobile && (
          <div style={{ margin: '-.4rem auto', width: '100%' }}>
            <Carousel
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {bannerMobile.map((item, index) => {
                return (
                  <div key={index} className={styles.carousel__item}>
                    <img
                      style={{
                        width: '100%',
                        height: '400px',
                        objectFit: 'cover',
                        margin: '0 auto'
                      }}
                      src={`https://api2.nestrental.com.br${item}`}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        )
      )}
    </>
  );
};
