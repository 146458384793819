import React from 'react';
import styles from './Home.module.scss';
import Header from 'src/components/Header/Header';
import Img01 from '../../assets/images/img01-home.jpg';
import Img02 from '../../assets/images/img02-home.png';
import Img03 from '../../assets/images/img03-home.jpg';
import Icon01 from '../../assets/images/icon01-home.jpg';
import Icon02 from '../../assets/images/icon02-home.jpg';
import Icon03 from '../../assets/images/icon03-home.jpg';

import Ticket from '../../assets/images/icon-plat/ticket.jpg';
import Hospital from '../../assets/images/icon-plat/hospital.jpg';
import Airplane from '../../assets/images/icon-plat/airplane.jpg';
import Build from '../../assets/images/icon-plat/build.jpg';
import Beach from '../../assets/images/icon-plat/beach.jpg';
import University from '../../assets/images/icon-plat/graduate.jpg';
import Shop from '../../assets/images/icon-plat/shop.jpg';
import Build2 from '../../assets/images/icon-plat/build2.jpg';
import GasCar from '../../assets/images/icon-plat/gascar.jpg';
import Theatre from '../../assets/images/icon-plat/theatre.jpg';
import Museum from '../../assets/images/icon-plat/museum.jpg';
import Sports from '../../assets/images/icon-plat/sport.jpg';
import Market from '../../assets/images/icon-plat/market.jpg';
import Cinema from '../../assets/images/icon-plat/cinema.jpg';
import Church from '../../assets/images/icon-plat/church.jpg';
import Bus from '../../assets/images/icon-plat/bus.jpg';
import Footer from 'src/components/Footer/Footer';
import MachinesHome from '../../assets/images/machines-home.png';

import { useMedia } from 'src/hooks/useMedia';
import { Link } from 'react-router-dom';
import { CarouselNest } from './Carousel';
import ZohoSalesIQ from 'src/components/ChatZoho/ChatZoho';
import { CarouselHome } from './CarouselHome/CarouselHome';

const Home = () => {
  const mobile = useMedia('(max-width: 768px)');

  const [bannerDesktop, setBannerDesktop] = React.useState('');
  const [bannerMobile, setBannerMobile] = React.useState('');

  async function getBannerDekstop() {
    const fetchBanner = fetch('https://api2.nestrental.com.br/api/arquivos/4', {
      method: 'GET'
    });

    const response = await fetchBanner;
    const json = await response.json();
    setBannerDesktop(json.anexo);
  }

  async function getBannerMobile() {
    const fetchBanner = fetch('https://api2.nestrental.com.br/api/arquivos/5', {
      method: 'GET'
    });

    const response = await fetchBanner;
    const json = await response.json();
    setBannerMobile(json.anexo);
  }

  React.useEffect(() => {
    getBannerDekstop();
    getBannerMobile();
  }, []);

  return (
    <>
      <Header />
      <section className={styles.home}>
        {mobile ? (
          <div className={styles.home__bannerMob}>
            <CarouselHome />
          </div>
        ) : (
          <div className={styles.home__banner}>
            <CarouselHome />
          </div>
        )}

        <div className={styles.home__concepts}>
          <div className={styles.home__concepts__box}>
            <div className={styles.home__concepts__box__icon}>
              <img src={Icon01} alt="icon-simply" />
            </div>
            <h2>Facilidade</h2>
            <p>
              É possível alugar uma plataforma da Nest Rental acessando
              diferentes canais: 0800, Whatsapp, Nest online (e-commerce) e nas
              estações Nest Express. Qualquer plataforma Low Level pode ser
              alugada por no mínimo dois dias úteis. Realizamos a entrega,
              treinamento de operadores e depois retiramos. Tudo muito simples,
              rápido e econômico.
            </p>
          </div>
          <div className={styles.home__concepts__box}>
            <div className={styles.home__concepts__box__icon}>
              <img src={Icon02} alt="icon-safe" />
            </div>
            <h2>Segurança</h2>
            <p>
              Nosso compromisso é oferecer equipamentos modernos e eficientes,
              que eliminam a fadiga e riscos nos locais de trabalho.
            </p>
          </div>
          <div className={styles.home__concepts__box}>
            <div className={styles.home__concepts__box__icon}>
              <img src={Icon03} alt="icon-productivity" />
            </div>
            <h2>Produtividade</h2>
            <p>
              Os equipamentos low level são até 4x mais produtivos que escadas e
              andaimes, na realização de trabalhos em alturas de até 10m. Os
              equipamentos reduzem a quantidade de pessoas e o tempo de execução
              nos locais de trabalho. Por serem leves e compactas, as
              plataformas podem ser transportadas em elevadores normais e se
              deslocam através de portas.
            </p>
          </div>
        </div>

        <div className={styles.home__enterprise}>
          <div className={styles.home__enterprise__img}>
            <img src={Img01} alt="" />
          </div>
          <div className={styles.home__enterprise__whoiam}>
            <label>Quem Somos</label>
            <h2>A empresa</h2>
            <p>
              A <b>Nest Rental</b> foi concebida para atender segmentos de
              mercado e aplicações não atendidas por soluções convencionais.
              Mesmo em países desenvolvidos a maior incidência de acidentes é
              causada por quedas de baixas alturas, e cerca de 70% dos acidentes
              graves envolveram uma escada.
            </p>
            <p>
              Alugamos e vendemos equipamentos inovadores, seguros e de baixo
              custo. Nossa solução é completa: entrega, treinamento dos
              operadores e retirada. Através de nossos canais alugamos nossos
              equipamentos a partir de 1 hora (24/7).
            </p>
            <p>
              Os equipamentos da Nest Rental contribuem para o aumento de
              produtividade e segurança nos locais de trabalho.
            </p>
          </div>
        </div>

        <div className={styles.home__rent}>
          <div className={styles.home__rent__text}>
            <h2>#tchauescada</h2>
            <p>
              Com equipamentos que oferecem ao mesmo tempo mais segurança e
              produtividade.
            </p>
            <Link to="/alugue-online">
              <button>Alugar</button>
            </Link>
          </div>
          <div className={styles.home__rent__img}>
            <img src={MachinesHome} alt="" />
          </div>
        </div>

        <div className={styles.home__enterprise2}>
          <div className={styles.home__enterprise2__img}>
            <img src={Img03} alt="" />
          </div>
          <div className={styles.home__enterprise2__text}>
            <h3>
              Precisa realizar trabalhos em altura até 10m e quer evitar risco
              de acidentes?
            </h3>
            <h3>Aumentar a produtividade e reduzir custos?</h3>
            <h4>Questões de segurança</h4>
            <p>
              Segurança precisa fazer parte da cultura do trabalho. As empresas
              comprometidas estão permanentemente em busca de melhorar as
              condições de trabalho de seus colaboradores e implementando novos
              processos. Os Equipamentos low level incorporam tecnologia para
              aumentar a produtividade.
            </p>
          </div>
        </div>

        <div className={styles.home__plat}>
          <h2>Onde as plataformas elevatórias são indispensáveis</h2>
          <div className={styles.home__plat__icons}>
            <div className={styles.home__plat__icons__box}>
              <div>
                <img src={Ticket} />
                <label>Eventos</label>
              </div>
              <div>
                <img src={Hospital} />
                <label>Hospitais e Clínicas</label>
              </div>
              <div>
                <img src={Airplane} />
                <label>Aeroportos</label>
              </div>
              <div>
                <img src={Build} />
                <label>Condomínios</label>
              </div>
            </div>
            <div className={styles.home__plat__icons__box}>
              <div>
                <img src={Beach} />
                <label>Clubes</label>
              </div>
              <div>
                <img src={University} />
                <label>Universidades e Escolas</label>
              </div>
              <div>
                <img src={Shop} />
                <label>Shoppings</label>
              </div>
              <div>
                <img src={Build2} />
                <label>Edifícios Comerciais</label>
              </div>
            </div>
            <div className={styles.home__plat__icons__box}>
              <div>
                <img src={GasCar} />
                <label>Postos de Gasolina</label>
              </div>
              <div>
                <img src={Theatre} />
                <label>Teatros</label>
              </div>
              <div>
                <img src={Museum} />
                <label>Museus e Galerias</label>
              </div>
              <div>
                <img src={Sports} />
                <label>Ginasios de Esportes</label>
              </div>
            </div>
            <div className={styles.home__plat__icons__box}>
              <div>
                <img src={Market} />
                <label>Supermercados</label>
              </div>
              <div>
                <img src={Church} />
                <label>Igrejas</label>
              </div>
              <div>
                <img src={Cinema} />
                <label>Cinemas</label>
              </div>
              <div>
                <img src={Bus} />
                <label>Rodoviarias</label>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.home__tec}>
          <h2>
            Plataformas de alta tecnologia e produtividade para uso imediato
          </h2>
          <CarouselNest />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
