import {Box, Container, Typography, Button} from '@mui/material'
import Footer from "src/components/Footer/Footer";
import Header from "src/components/Header/Header";
import WebFormLp from 'src/components/WebFormLp/WebFormLp';
import {Link} from 'react-router-dom'
import styles from './LpNest.module.scss';
import ZohoSalesIQ from 'src/components/ChatZoho/ChatZoho';
const LpNest = ()=>{
    return(
        <>
            <Header/>
            <Box className={styles.lpnest}>
                <Container style={{textAlign: 'center'}}>
                    <Typography align="center" color="primary" variant="h1" className={styles.lpnest__title}
                        sx={{p: '1rem', marginTop: '2rem', textTransform: 'uppercase', fontWeight: '700'}}>
                            Promoção LIFTPOD FT140
                    </Typography>
                    <Typography align="center" color="primary" variant="h3" sx={{p: '1rem'}}>A partir de R$15.850,00</Typography>
                    <Typography align="center" color="primary" variant="h4" sx={{p: '1rem'}}>Assista o vídeo para conhecer o equipamento!</Typography>
                    <Typography align="center" color="primary" variant="h4" sx={{p: '1rem'}}>Alcança 6m de altura de trabalho</Typography>
                    <iframe 
                        width="778" 
                        height="433" 
                        src="https://www.youtube.com/embed/fBbOPo4KA3s" 
                        title="LiftPod® FT Series: JLG" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>

                    <Link to={`/produto/liftpod-140`} state={{id: 34}} >
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem auto'}}
                            >
                            Mais informações
                        </Button>
                    </Link>
                </Container>
            </Box>
            <Box>
                <Container style={{textAlign: 'center'}}>
                    <Typography align="center" color="primary" variant="h3" sx={{p: '1rem', marginTop: '2rem'}} className={styles.lpnest__text}>
                        Agende agora uma demonstração sem nenhum custo e conheça as vantagens da Liftpod Ft140
                    </Typography>
                    <Typography align="center" color="primary" variant="h4" sx={{p: '1rem'}}>
                        *Valor mostrado acima é referente ao equipamento seminovo e 90 dias de garantia.
                    </Typography>
                   <WebFormLp/>
                </Container>
            </Box>
            <Footer/>
        </>
    )
}

export default LpNest;