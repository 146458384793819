import { LoadingButton } from '@mui/lab';
import { Title } from 'src/components/Title/Title';
import styles from './Training.module.scss';
import React, { useEffect } from 'react';
//import PDF from '../../public/dispensa-treinamento.pdf';

const Training = ({
  modal,
  setModal,
  detalhesPedido,
  alunos,
  setAlunos,
  idPedido
}) => {
  const {
    startDate,
    endDate,
    price,
    newPrice,
    billing,
    totalDays,
    idPerfil,
    idProduct,
    dataNewUser
  } = detalhesPedido;

  const [checkTraining, setCheckTraining] = React.useState(false);

  /*Aluno 1*/
  const [nomeAluno1, setNomeAluno1] = React.useState('');
  const [emailAluno1, setEmailAluno1] = React.useState('');
  const [telAluno1, setTelAluno1] = React.useState('');
  const [passwordAluno1, setPasswordAluno1] = React.useState('');

  /*Aluno 2*/
  const [nomeAluno2, setNomeAluno2] = React.useState('');
  const [emailAluno2, setEmailAluno2] = React.useState('');
  const [telAluno2, setTelAluno2] = React.useState('');
  const [passwordAluno2, setPasswordAluno2] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [errorDispensa, setErrorDispensa] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [successDispensa, setSuccessDispensa] = React.useState('');

  const [cpfAluno1, setCpfAluno1] = React.useState('');
  const [cpfAluno2, setCpfAluno2] = React.useState('');

  const [cpfDispensaAluno1, setCpfDispensaAluno1] = React.useState('');
  const [cpfDispensaAluno2, setCpfDispensaAluno2] = React.useState('');

  let id2;
  async function verifyId() {
    if (idProduct == 1) {
      return 8;
    }
    if (idProduct === 44) {
      return 12;
    }
    if (idProduct === 4) {
      return 8;
    }
    if (idProduct === 34) {
      return 8;
    }
    if (idProduct === 14) {
      return 8;
    }
    if (idProduct === 24) {
      return 8;
    }
    if (idProduct === 54) {
      return 8;
    }
    if (idProduct === 166) {
      return 8;
    }
    if (idProduct === 167) {
      return 8;
    }
    if (idProduct === 168) {
      return 8;
    }
    if (idProduct === 169) {
      return 8;
    }
  }

  const handleSubmitEnrollment = async (id_aluno: number) => {
    const fetchUser = fetch(
      'https://api2.nestrental.com.br/api/ead/student/enrollment',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          curso_id: await verifyId(),
          usuario_id: id_aluno
        })
      }
    );
    const response = await fetchUser;
    const jsonResponse = await response.json();
    return true;
  };

  const handleSubmitUserOne = async (nome, email, senha, telefone, cpf) => {
    setLoading(true);
    const fetchUser = fetch('https://api2.nestrental.com.br/api/ead/student', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        nome: nome,
        senha: senha,
        telefone: telefone,
        cpf: cpf,
        tipo: 1,
        status: 1,
        personalizado: dataNewUser[0].id
      })
    });
    const response = await fetchUser;
    const jsonResponse = await response.json();

    if (jsonResponse.http_status == 200) {
      let cadCourse = await handleSubmitEnrollment(jsonResponse.data.aluno_id);
      if (cadCourse) {
        return true;
      } else {
        return false;
      }
    } else if (jsonResponse.http_status == 409) {
      return false;
    }
  };

  const cadastraAlunos = async (e: any) => {
    e.preventDefault();
    if (checkTraining === false) {
      const aluno1 = await handleSubmitUserOne(
        nomeAluno1,
        emailAluno1,
        passwordAluno1,
        telAluno1,
        cpfAluno1
      );

      const aluno2 = await handleSubmitUserOne(
        nomeAluno2,
        emailAluno2,
        passwordAluno2,
        telAluno2,
        cpfAluno2
      );

      if (aluno1 && aluno2) {
        setError('');
        setSuccess(
          'Alunos matriculados com sucesso, por favor, verifique sua caixa de E-mail!'
        );
        setTimeout(() => {
          setAlunos(!alunos);
          setModal(!modal);
        }, 3000);
      } else {
        setError(
          'Erro na matrícula dos alunos, por favor, verifique os dados e tente novamente!'
        );
        setSuccess('');
      }
      setLoading(false);
    } else if (checkTraining === true) {
      const dispensa1 = await dispensaTreinamento(
        nomeAluno1,
        cpfDispensaAluno1,
        emailAluno1,
        telAluno1
      );
      const dispensa2 = await dispensaTreinamento(
        nomeAluno2,
        cpfDispensaAluno2,
        emailAluno2,
        telAluno2
      );
      if (dispensa1 && dispensa2) {
        setErrorDispensa('');
        setSuccessDispensa('Dispensa realizada com sucesso.');
        setTimeout(() => {
          setAlunos(!alunos);
          setModal(!modal);
        }, 3000);
      } else {
        setErrorDispensa(
          'Erro na dispensa do treinamento, por favor, verifique os dados e tente novamente!'
        );
        setSuccessDispensa('');
      }
      setLoading(false);
    }
  };

  const dispensaTreinamento = async (nome, cpf, email, telAluno) => {
    const fetchUser = fetch('https://api2.nestrental.com.br/api/treinamentos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nome: nome,
        email: email,
        cpf: cpf,
        telefone: telAluno,
        id_termo: 2,
        id_pedido: idPedido
      })
    });
    const response = await fetchUser;
    if (response.status === 202) {
      return true;
    } else if (response.status === 404 || response.status === 400) {
      return false;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5rem'
      }}
    >
      <section className={styles.warningTraining}>
        <div>
          <Title>Treinamento</Title>
          <form className={styles.warningTraining__form}>
            <div className={styles.warningTraining__form__aluno1}>
              <label style={{ display: 'block', fontWeight: 'bold' }}>
                {' '}
                Aluno 1°
              </label>
              <div className={styles.warningTraining__form__aluno1__inputs1}>
                <div>
                  <input
                    type="text"
                    placeholder="Nome"
                    required
                    onChange={(e) => setNomeAluno1(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmailAluno1(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="CPF"
                    required
                    onChange={(e) => setCpfAluno1(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
              </div>
              <div className={styles.warningTraining__form__aluno1__inputs1}>
                <div>
                  <input
                    type="text"
                    placeholder="(00) 00000000"
                    required
                    onChange={(e) => setTelAluno1(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Senha"
                    required
                    onChange={(e) => setPasswordAluno1(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
              </div>
            </div>

            <div className={styles.warningTraining__form__aluno2}>
              <label
                style={{
                  display: 'block',
                  marginTop: '1.5rem',
                  fontWeight: 'bold'
                }}
              >
                {' '}
                Aluno 2°
              </label>
              <div className={styles.warningTraining__form__aluno2__inputs2}>
                <div>
                  <input
                    type="text"
                    placeholder="Nome"
                    required
                    onChange={(e) => setNomeAluno2(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmailAluno2(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="CPF"
                    required
                    onChange={(e) => setCpfAluno2(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
              </div>
              <div className={styles.warningTraining__form__aluno2__inputs2}>
                <div>
                  <input
                    type="text"
                    placeholder="(00) 00000000"
                    required
                    onChange={(e) => setTelAluno2(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    placeholder="Senha"
                    required
                    onChange={(e) => setPasswordAluno2(e.target.value)}
                    disabled={checkTraining}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div>
          {error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <p style={{ color: 'green' }}>{success}</p>
          )}
        </div>

        <div className={styles.warningTraining__notTraining}>
          <input
            type="checkbox"
            onChange={(e) => setCheckTraining(!checkTraining)}
          />
          <label> Já tenho treinamento </label>
        </div>

        {checkTraining && (
          <div style={{ marginTop: '1rem' }}>
            <Title>Dispensa de Treinamento</Title>
            <div>
              <form className={styles.warningTraining__form}>
                <div className={styles.warningTraining__form__aluno1}>
                  <label style={{ display: 'block', fontWeight: 'bold' }}>
                    {' '}
                    Aluno 1°
                  </label>
                  <div
                    className={styles.warningTraining__form__aluno1__inputs1}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Nome"
                        required
                        onChange={(e) => setNomeAluno1(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmailAluno1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className={styles.warningTraining__form__aluno1__inputs1}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="000.000.000-00"
                        required
                        onChange={(e) => setCpfDispensaAluno1(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="(00) 00000000"
                        required
                        onChange={(e) => setTelAluno1(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.warningTraining__form__aluno2}>
                  <label
                    style={{
                      display: 'block',
                      marginTop: '1.5rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {' '}
                    Aluno 2°
                  </label>
                  <div
                    className={styles.warningTraining__form__aluno2__inputs2}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Nome"
                        required
                        onChange={(e) => setNomeAluno2(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Email"
                        required
                        onChange={(e) => setEmailAluno2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div
                    className={styles.warningTraining__form__aluno2__inputs2}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="000.000.000-00"
                        required
                        onChange={(e) => setCpfDispensaAluno2(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="(00) 00000000"
                        required
                        onChange={(e) => setTelAluno2(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div>
                {errorDispensa ? (
                  <p style={{ color: 'red' }}>{errorDispensa}</p>
                ) : (
                  <p style={{ color: 'green' }}>{successDispensa}</p>
                )}
              </div>
            </div>
            <div className={styles.warningTraining__text}>
              <a
                href="/"
                download="dispensa-treinamento.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Optando pela recusa, afirmo que estou ciente dos requisitos
                referente a treinamento de operador (es) contidos no anexo IV da
                NR-18 oferecidos pela Nest Rental e informo que a mesma está
                isenta de toda e qualquer responsabilidade legal referente a
                este item, inclusive acidentes e sinistros de qualquer natureza
                que possa advir da operação inadequada do referido equipamento.
              </a>
            </div>
          </div>
        )}
      </section>

      <div
        style={{
          backgroundColor: '#f1f1f1',
          width: 'auto',
          borderRadius: '1rem',
          padding: '2rem 2rem 2rem 2rem',
          height: '40%'
        }}
      >
        <form>
          <div
            style={{
              display: 'flex',
              gridGap: '2rem',
              width: '100%',
              color: 'rgb(18, 80, 130)'
            }}
          >
            <div style={{ width: '100%' }}>
              <label
                htmlFor="start"
                style={{ display: 'block', fontWeight: '600' }}
              >
                início
              </label>
              <input
                type="date"
                name="start"
                value={startDate}
                disabled
                style={{
                  width: '100%',
                  border: 'none',
                  padding: '.5rem .5rem',
                  background: '#c2c2c2',
                  borderRadius: '8px'
                }}
              />
            </div>
            <div>
              <label
                htmlFor="end"
                style={{ display: 'block', fontWeight: '600' }}
              >
                Devolução
              </label>
              <input
                type="date"
                name="end"
                value={endDate}
                disabled
                style={{
                  width: '100%',
                  border: 'none',
                  padding: '.5rem .5rem',
                  background: '#c2c2c2',
                  borderRadius: '8px'
                }}
              />
            </div>
          </div>

          <div>
            <p>Quantidade de dias: {totalDays}</p>
            <div>
              <p style={{ paddingTop: '.5rem' }}>
                Frete:{' '}
                {billing.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL'
                })}
              </p>
              <p style={{ paddingTop: '.5rem' }}>
                Aluguel:{' '}
                {idPerfil === 3
                  ? newPrice.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : price.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                <span>
                  {price != 0 && idPerfil != 3 ? (
                    <span style={{ fontSize: '.8rem', color: 'green' }}>
                      {' '}
                      (5% de desconto)
                    </span>
                  ) : (
                    ''
                  )}
                </span>
              </p>
              <p style={{ paddingTop: '.5rem' }}>
                <b>TOTAL: </b>
                {billing != 0 && idPerfil != 3
                  ? (price + billing).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })
                  : (newPrice + billing).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
              </p>
            </div>
          </div>
          <LoadingButton
            type="submit"
            color="success"
            fullWidth={true}
            loading={loading ? true : false}
            variant="contained"
            onClick={(e) => cadastraAlunos(e)}
          >
            Continuar
          </LoadingButton>
        </form>
      </div>
    </div>
  );
};

export default Training;
