import React, { useEffect, useState } from 'react';
import Rent from './Rent';
import styles from './SingleProduct.module.scss';
import ProductDescription from './ProductDescription/ProductDescription';
import Container from '../../components/Container/Container';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import { api } from 'src/api/api';
import { checkContext } from 'src/context/CheckoutContext';
import { useLocation } from 'react-router';

const SingleProduct = () => {
  const [produto, setProduto] = useState(null);
  const location = useLocation();
  const { setIdProduct } = React.useContext(checkContext);

  let id2;

  async function carregar(id2: number) {
    const { data } = await api.get(`/produtos/${id2}`);
    setProduto(data);
  }

  function verifyId() {
    let href = window.location.href;
    // console.log({ href, location: location.pathname });
    let identificador = location.pathname.substring(9);
    // console.log({identificador})
    // let identificador = href.substring(30);
    //let identificador = href.substring(28);
    if (identificador === '30am') {
      id2 = 54;
    }
    if (identificador === 'toucant-t26e') {
      id2 = 44;
    }
    if (identificador === 'ecolift-50') {
      id2 = 1;
    }
    if (identificador === 'liftpod-ft-140') {
      id2 = 34;
    }
    if (identificador === '1030p-duo') {
      id2 = 24;
    }
    if (identificador === '1030p') {
      id2 = 14;
    }
    if (identificador === 'ecolift-70') {
      id2 = 4;
    }
    if (identificador === 'jcpt0507pa') {
      id2 = 166;
    }
    if (identificador === 'jcpt0607a') {
      id2 = 167;
    }
    if (identificador === 'jcpt0607dcm') {
      id2 = 168;
    }
    if (identificador === 'opt0507') {
      id2 = 169;
    }
    if (identificador === "toucant-26e") {
      id2 = 44;
    }

    setIdProduct(id2);
    carregar(id2);
  }

  useEffect(() => {
    verifyId();
  }, []);

  useEffect(() => {
    console.log({produto});
  }, [produto])

  return (
    <>
      <Header />
      <section
        style={{
          backgroundColor: '#125082',
          padding: '1rem 5rem',
          height: '150px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <h1
          style={{
            color: '#fff',
            textTransform: 'uppercase'
          }}
        >
          {produto ? produto[0]?.nome : ''}
        </h1>
      </section>
      <Container>
        <section className={styles.singleProduct}>
          <div className={styles.singleProduct__img}>
            <img
              src={
                produto
                  ? `https://api2.nestrental.com.br${produto[0]?.prod_image}`
                  : null
              }
            ></img>
          </div>

          <Rent
            valor={produto ? produto[0]?.valor : ''}
            nome={produto ? produto[0]?.nome : ''}
            id={id2 ? id2 : null}
          />
        </section>
        <ProductDescription descricao={produto ? produto[0]?.descricao : ''} />
      </Container>
      <Footer />
    </>
  );
};

export default SingleProduct;
