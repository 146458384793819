import axios from 'axios';

//endpoint API
export const API_URL = 'https://api2.nestrental.com.br/api';

let token = null;

export const api = axios.create({
  baseURL: API_URL
});

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*'
  };
  return config;
});

export function logar(jwtToken: string) {
  token = jwtToken;
}

//Retorna o usuário pelo token
export function USER_GET(token: string) {
  return {
    url: API_URL + '/usuarios/login',
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  };
}

//Retorna o token do usuário
export function TOKEN_POST(user_email: string, user_password: string) {
  return {
    url: API_URL + '/usuarios/login',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        login: user_email,
        password: user_password
      })
    }
  };
}

//Retorna o token do usuário
export function POST_EMAIL_RECOVERY(user_email: string) {
  return {
    url: API_URL + '/usuarios/recovery',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: user_email
      })
    }
  };
}

//whatsapp
export async function getWhatsapp(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/6`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching WhatsApp:', error);
    // Handle error as needed
  }
}

//email
export async function getEmail(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/5`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching Email:', error);
    // Handle error as needed
  }
}

//phone
export async function getPhone(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/7`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching Phone:', error);
    // Handle error as needed
  }
}

//facebook
export async function getFacebook(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/1`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching Facebook:', error);
    // Handle error as needed
  }
}

//instagram
export async function getInstagram(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/4`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching Instagram:', error);
    // Handle error as needed
  }
}

//linkedin
export async function getLinkedin(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/3`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching LinkedIn:', error);
    // Handle error as needed
  }
}

//youtube
export async function getYoutube(setData) {
  try {
    const response = await axios.get(`${API_URL}/redes-sociais/2`);
    setData(response.data.url);
  } catch (error) {
    console.error('Error fetching YouTube:', error);
    // Handle error as needed
  }
}
