import ReactWhatsapp from 'react-whatsapp';
import styles from './WhatsappFloat.module.scss';

const WhatsappFloat = ()=>{
    return(
        <a
        href="https://api.whatsapp.com/send?phone=5511988446299"
        className={styles.whatsapp_float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    )
}

export default WhatsappFloat;