
import React from "react";
import styles from './Document.module.scss'
import { useNavigate } from "react-router";
import {BiArrowBack} from 'react-icons/bi'
export const Document0 = ({token, setToken}) =>{

    const navigate = useNavigate();

    var widget = '';
    var input = '';
 
    setTimeout(()=>{
        widget = window.document.querySelector("#request_signature_key");
        input = window.document.querySelector("#request_signature_key");
    }, 3000)

    
    
    function Clicksign(i){
        "use strict";
        function n(n){
            var t;
            (e[(t=n).name||t]||[]).forEach(function(t){
                t(n.data)})}
                var o,r,t=window.location.protocol+"//"+window.location.host,e={}
                ,u=function(t){n(t.data)};
                return{
                    endpoint:"https://app.clicksign.com",
                    origin:t,
                    mount:function(t){
                        var n="/sign/"+i,
                        e="?embedded=true&origin="+this.origin;
                        e=this.endpoint+n+e;
                        return r=document.getElementById(t),(o=document.createElement("iframe")).setAttribute("src",e),
                        o.setAttribute("style","width: 100%; height: 100%;"),
                        o.setAttribute("allow","camera"),window.addEventListener("message",u),
                        r.appendChild(o)
                    },
                    unmount:function(){
                        return o&&(r.removeChild(o),o=r=null,window.removeEventListener("message",n)),!0},on:function(t,n){return e[t]||(e[t]=[]),e[t].push(n)},trigger:n}
    }

    function run(){
       
        if(widget){widget.unmount();}
        widget = new Clicksign(token);

        widget.endpoint = 'https://app.clicksign.com';
        widget.origin = 'https://nestrental.com.br';
        widget.mount('container');

        widget.on('loaded', function(ev){
            console.log('loaded!'); 
        });
        widget.on('signed', function(ev){
            console.log('navegar agora')
            window.location.href = 'http://www.nestrental.com.br/cadastro-ead';
            navigate('/cadastro-ead');
        });

        widget.on('resized', function(height) {
          console.log('resized!');
          document.getElementById('container').style.height = height+'px';
        });
        console.log('ww')
    }

    React.useEffect(()=>{
        run();
    }, [])

    window.localStorage.setItem('option_training', '');
   
    return(
        <section className={styles.document}>
            <div>
                <input id='request_signature_key' style={{display: 'none'}}/>
            </div>

            <div id='container' style={{height: "600px", padding: '2rem'}}>
                <a href="https://nestrental.com.br" 
                    style={
                        {
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '-1rem',
                            gridGap: '7px'
                        }
                        }
                >
                    <BiArrowBack size={30} color="#125082"/>
                    <p style={{textDecoration: 'none', color: '#125082'}}>Voltar ao site e assinar depois</p>
                </a>
            </div>
        </section>
    );
}