import React from 'react';
import styles from './Products.module.scss';
import { Link } from 'react-router-dom';
import { api } from 'src/api/api';
import { Button } from '@mui/material';
export const Products = () => {
  const [dataProducts, setDataProducts] = React.useState([]);

  async function carregar() {
    const { data } = await api.get(`/produtos`);
    setDataProducts([...data]);
  }

  React.useEffect(() => {
    carregar();
  }, []);

  return (
    <React.Fragment>
      {dataProducts
        ? dataProducts.map((item, index) => {
            return (
              <div key={index} className={styles.products}>
                <img src={`https://api2.nestrental.com.br${item.prod_image}`} alt={`${item.nome}`} />
                <h2>{item.nome}</h2>
                <p>
                  <label>Dimensões compactas: </label>
                  {item.dimensao}
                </p>
                <p>
                  <label>Altura de trabalho: </label>
                  {item.altura}
                </p>
                {item.nome == 'TOUCAN T26E' ||
                item.nome == 'JCPT0507PA' ||
                item.nome == 'JCPT0607DCM' ||
                item.nome == 'OPT0507' ? (
                  <p>
                    <label> </label>
                    {item.acionamento}
                  </p>
                ) : (
                  <p>
                    <label>Acionamento:</label> {item.acionamento}
                  </p>
                )}

                <Link
                  to={`/produto/${item.identificador}`}
                  state={{ id: item.id }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth={true}
                  >
                    Alugar
                  </Button>
                </Link>
              </div>
            );
          })
        : ''}
    </React.Fragment>
  );
};
