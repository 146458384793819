import { Title } from '../../components/Title/Title';
import React from 'react';
import styles from './LoginForm.module.scss';
import { Input } from 'src/components/Input/Input';
import { useForm } from 'src/hooks/useForm';
import { Link,  } from 'react-router-dom';
import MachineLogin from '../../assets/images/machine-login.png'
import Logo from '../../assets/logo.jpg'
import { useContext, useEffect } from 'react';
import { UserContext } from 'src/context/UserContext';
import { TextField, Button } from '@mui/material';
import { useState } from 'react';
import {LoadingButton} from '@mui/lab'


export const LoginForm = () => {

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")

    const {
        userLogin,
        error, 
        message,
        setError
    } = useContext( UserContext );

    const login = async (event: any) => {
        event.preventDefault();
        userLogin(email, password);
    }

    useEffect(()=>{
       setTimeout(()=>{
        setError('')
       },2000)
    }, [error])
  


    return (
        <section className={styles.loginForm}>
            <div className={styles.loginForm__background}>
                <img src={MachineLogin}/>
            </div>
            <div className={styles.loginForm__divForm}>
                <img src={Logo}/>
                <Title>Bem vindo de volta!</Title>
                <form onSubmit={login}>
                    <div className={styles.loginForm__divForm__input}>
                        <TextField
                            label="Email"
                            onChange={event=>setEmail(event.target.value)}
                            id="user_email"
                            placeholder="Digite seu e-mail"
                            fullWidth={true}
                            required={true}
                        />
                    </div>
                   
                    <div className={styles.loginForm__divForm__input}>
                        <TextField
                            label="Senha"
                            onChange={event=>setPassword(event.target.value)}
                            id="user_email"
                            placeholder="Digite sua senha"
                            fullWidth={true}
                            type="password"
                            required={true}
                        />
                     </div>

                     <div className={styles.loginForm__divForm__buttonSubmitAndForgetPassword}>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained" 
                        >
                            Entrar
                        </LoadingButton>

                        <div className={styles.loginForm__divForm__recoveryPassword}>
                            <div>
                                <Link to="/recuperar-senha" style={{color: "rgba(18, 80, 130, 1)"}}>
                                    Esqueceu sua senha?
                                </Link>
                            </div>
                        </div>
                     </div>
                    {error? 
                        <p style={{color: "red"}}>{error}</p>
                        : 
                        <p style={{color:"green"}}>{message}</p>
                    }
                </form>

                <hr/>

                <div className={styles.loginForm__divForm__forgetPassword}>
                    <p>Você não tenha uma conta?
                        <Link to="/cadastro" style={{color: "rgba(18, 80, 130, 1)", fontWeight: "bold"}}> Inscreva-se</Link>
                    </p>
                    <p style={{textAlign: 'right', marginTop: '1rem'}}>
                        <Link to="/" style={{color: "rgba(18, 80, 130, 1)", fontWeight: "bold"}}>Voltar ao site</Link>
                    </p>                    
                </div>
            </div>
        </section>
    )
}
